import '../../../../css/solutions.css';
import Nav from '../../../Nav';
import Footer from '../../../footer';
import AnalyticsSolutions from '../data&analyticslist';
import Platforms from '../../../marquee_platforms/platforms';
import Join from '../../../about/joinus';

import imgPath from '../../../../images/provesyellow.png'


function BigData() {


    const height = { height: '16rem' }

    return (


        <div className='main-page'>

            <Nav  bgColor='rgb(255, 215, 0);' fontcolor='yellow' imgPath={imgPath} />
            <div className='about-main'>
                <div className='bigdata-bg'>
                    <br /> <br /> <br /><br /> <br /> <br /> <br /><br /><br />

                    <div className='container'>
                        <div className='row'>

                            <div className='col'>
                                <div className='solutions-box'>
                                    <h4>SOLUTIONS / BIG DATA ANALYTICS</h4>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='container'>
                    <Platforms />

                    <div className='row'>

                        <div className='col-md-6'>
                            <h2 className='about-heading'>Unlocking transformative insights and innovations through data to drive strategic decisions.</h2>
                            <br />
                        </div>

                        <div className='col'>
                            <p className='about-company-paragraph'>
                            Big data services commonly provide robust data storage and management solutions capable of handling massive volumes of diverse data types. They also offer advanced analytics capabilities, 
                            including predictive analytics and machine learning, to extract meaningful insights and patterns from the data. 
                        
                            </p>

                        </div>

                    </div>

                </div>


                <div className='services-bg'>
                    <div className='container'>
                        <div className='row'>

                            <center><h3 className='about-heading'>  <br /><br /><br />Services</h3>  <br /><br /></center>

                            <div className='col-lg-4 col-md-6'>
                                <div style={height} className='cards-industries'>
                                    <h4 className='about-heading'>Data Engineering</h4>
                                    <p className='about-company-paragraph'>Ensure your data infrastructure can handle large volumes of data with our data engineering services. We design and implement scalable data pipelines, storage solutions, and processing frameworks to support your big data initiatives.</p>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6'>
                                <div style={height} className='cards-industries'>
                                    <h4 className='about-heading'>Real-Time Analytics</h4>
                                    <p className='about-company-paragraph'>Gain real-time insights into your operations with our real-time analytics services. We enable you to process and analyze streaming data, allowing you to respond quickly to changing conditions and make timely decisions.</p>
                                </div>
                            </div>



                        </div>
                    </div>
                    <br />   <br />
                </div>
                <br /> <br /> <br /> <br />
                <Join />
                <br /> <br /> <br /> <br /> <br />

                <AnalyticsSolutions />

            </div>
            <Footer />
        </div>

    )


}

export default BigData

