import '../../../../css/services/epm.css';
import Nav from '../../../Nav';
import Footer from '../../../footer';
import EPMLIST from '../epmserviceslist';
import Join from '../../../about/joinus';

// sap images
import analytics  from '../../../../images/epmlist/epmservices/sap/sap-analytics.jpg'
import business from '../../../../images/epmlist/epmservices/sap/sap-business.jpeg'
import imgPath from '../../../../images/proveslogo(1).png'



function SAP() {
    return (

        <div className='main-page'>
            <Nav bgColor='rgb(255, 215, 0);' fontcolor='yellow' imgPath={imgPath} />


            <div className='about-main'>
                <div className="sap-image">
                    <br /> <br /> <br /><br /> <br /> <br /> <br /><br />

                    <div className='container'>
                        <div className='row'>

                            <div className='col'>
                                <div className='solutions-box'>
                                    <h4 className='about-heading'>EPM / SAP</h4>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <br /> <br /> <br /> <br /> <br />


                <div className='container'>
                    {/* First Row */}
                    <div className='row'>


                        <div className='col-1'></div>

                        <div data-aos="fade-in" data-aos-easing="ease-in-out-quad" data-aos-duration="2800" className='col-0_5'><h3 className='epm-number'>01</h3></div>

                        <div data-aos="fade-right" data-aos-easing="ease-in-out-quad" data-aos-duration="1800" className='col-md-4'>

                            <h4 className='about-heading'>SAP Business Planning and Consolidation (BPC)</h4>
                            <p className='about-company-paragraph'>Our SAP BPC services help you streamline planning, budgeting, forecasting, and financial consolidation processes.
                                We ensure seamless integration with your existing SAP environment, enabling real-time financial reporting and enhanced decision-making capabilities.</p>

                        </div>

                        <div className='col-1'></div>


                        <div className='col-md-4'>

                            <div className="image-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="1800">
                                <img className="oracle-images" src={business} />
                            </div>
                        </div>


                    </div>

                    <br /><br />  <br /><br />


                    {/* Second Row */}
                    <div className='row'>


                        <div className='col-1'></div>

                        <div data-aos="fade-in" data-aos-easing="ease-in-out-quad" data-aos-duration="2800" className='col-0_5'><h3 className='epm-number'>02</h3></div>

                        <div data-aos="fade-right" data-aos-easing="ease-in-out-quad" data-aos-duration="1800" className='col-md-4'>

                            <h4 className='about-heading'>SAP Analytics Cloud (SAC)</h4>
                            <p className='about-company-paragraph'>Leverage the power of SAP Analytics Cloud for comprehensive planning, predictive analytics, and business
                                intelligence. Our experts assist you in implementing and optimizing SAC to gain actionable insights and drive strategic growth.</p>

                        </div>

                        <div className='col-1'></div>


                        <div className='col-md-4'>

                            <div className="image-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="1800">
                                <img className="oracle-images" src={analytics} />
                            </div>
                        </div>


                    </div>





                </div>

                <br /><br />
                <EPMLIST />
                <br /><br />
                <Join />
                <br /><br /><br /><br />

            </div>
            <Footer />
        </div>
    )
}



export default SAP


