
import '../../css/industries.css';
import Nav from '../Nav';
import Footer from '../footer';
import Join from '../about/joinus';
import Platforms from '../marquee_platforms/platforms';
import Industries from '../about/industries';


import imgPath from '../../images/proveslogo(1).png'

function Sector() {

    const height = { height: '16rem' }
   


    return (
        <div className='main-page'>

            <Nav bgColor='rgb(255, 215, 0);' fontcolor='black' imgPath={imgPath}/>
            <div className='about-main'>
                <div className='sectors-bg'>
                    <br /> <br /> <br /><br /> <br /> <br /> <br /><br />

                    <div className='container'>
                        <div className='row'>

                            <div className='col'>
                                <div className='solutions-box'>
                                    <h4 className='manufacture-header'>INDUSTRY / GOVERNMENT & PUBLIC SECTOR</h4>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='container'>
                    <Platforms />

                    <div className='row'>

                    </div>

                </div>


                <div className='services-bg'>
                    <div className='container'>
                        <div className='row'>

                            <center><h3 className='about-heading'>  <br />What We Do</h3>  <br /><br /></center>

                            <div className='col-lg-4 col-md-6'>
                                <div style={height} className='cards-industries'>
                                    <h4 className='about-heading'>
                                    Policy and Program Analysis
                                    </h4>
                                    <p className='industry-text'>

                                    Support evidence-based decision-making with our policy and program analysis services. We help government agencies analyze data to evaluate program effectiveness, allocate resources, and develop impactful policies.
                                    </p>
                                </div>
                            </div>


                            <div className='col-lg-4 col-md-6'>
                                <div style={height} className='cards-industries'>
                                    <h4 className='about-heading'>Public Safety</h4>
                                    <p className='industry-text'>
                                    Enhance public safety and security with our advanced analytics solutions. We assist law enforcement agencies in crime analysis, resource allocation, and predictive policing to improve community safety.
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6'>
                                <div style={height} className='cards-industries'>
                                    <h4 className='about-heading'>Operational Efficiency</h4>
                                    <p className='industry-text'>
                                    Improve operational efficiency and service delivery with our data-driven insights. We help public sector organizations optimize processes, manage resources, and measure performance.
                                    </p>
                                </div>
                            </div>


                        </div>
                        <br/><br/>
                    </div>
                </div>
                <br /> <br /> <br /> <br />
                <Join />
                <br /> <br /> 

                <Industries />

            </div>
            <br /> <br />  <br /> <br />
            <Footer />
        </div>
    )

}

export default Sector



