import anaplan from '../../images/platforms/anaplan.png'
import oracle from '../../images/platforms/oracle.jpg'
import sap from '../../images/platforms/sap.jpg'
import ibm from '../../images/platforms/ibm.png'
import onestream from '../../images/platforms/onestream.png'
import tangetik from '../../images/platforms/tangik.webp'
import insights from '../../images/platforms/adaptiveinsights.png'
import board from '../../images/platforms/board.webp'


function Platforms() {


    return (
        <div>
            <marquee ><img className='platform-img' src={oracle}/>
                <img className='platform-img' src={sap} />
                <img className='platform-img' src={ibm}/> &nbsp; &nbsp; &nbsp;
                <img className='platform-img' src={anaplan} /> &nbsp; &nbsp; &nbsp;
                <img className='platform-img' src={onestream} /> &nbsp; &nbsp; &nbsp;
                <img className='platform-img' src={tangetik} /> &nbsp; &nbsp; &nbsp;
                <img className='platform-img' src={insights}/> &nbsp; &nbsp; &nbsp;
                <img className='platform-img' src={board} />
            </marquee>
        </div>
    )
}

export default Platforms