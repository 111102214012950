import '../../css/about.css';
import expert from '../../images/about/expert.jpg'
import approach from '../../images/about/approach.webp'
import integrity from '../../images/about/integrity.jpg'
import innovation from '../../images/about/innovation.webp'


function WhyChoose()  {
    return(
        <div>
              {/* Third Row */}
              <div className='row all'>
                        <center>
                            <h2 className='about-company-header'>WHY CHOOSE US</h2>
                            <br /><br /><br /><br /><br /><br />
                        </center>

                        <div className='columns-container'>
                            <div className='col col-all'>
                                <div className='cards'>
                                    <h3 className='about-company-header'>EXPERTISE</h3>
                                    <img className='why-choose' src={expert} alt="Expertise" />
                                    <p className='text-whychoose'>Our team consists of seasoned professionals with extensive experience in data analytics, machine learning, and AI. We bring deep technical knowledge and industry insights to every project.</p>
                                </div>
                            </div>

                            <div className='col col-all'>
                                <div className='cards'>
                                    <h3 className='about-company-header'>INNOVATION</h3>
                                    <img className='why-choose' src={innovation} alt="Innovation" />
                                    <p className='text-whychoose'>We stay at the forefront of technology, exploring new methods and tools to enhance our solutions. Our innovative approach ensures you receive the most effective and current analytics services.</p>
                                </div>
                            </div>

                            <div className='col col-all'>
                                <div className='cards'>
                                    <h4 className='about-company-header'>CLIENT CENTRIC APPROACH</h4>
                                    <img className='why-choose' src={approach} alt="Client Centric Approach" />
                                    <p className='text-whychoose'>We prioritize our clients' needs and work closely with them to understand their unique challenges and goals. Our tailored solutions are designed to deliver measurable results and tangible value.</p>
                                </div>
                            </div>

                            <div className='col col-all'>
                                <div className='cards'>
                                    <h3 className='about-company-header'>INTEGRITY</h3>
                                    <img className='why-choose' src={integrity} alt="Integrity" />
                                    <p className='text-whychoose'>We are committed to maintaining the highest standards of integrity, transparency, and ethical conduct in all our professional engagements. Your trust and complete satisfaction are always our top priorities.</p>
                                </div>
                            </div>
                        </div>
                    </div>

        </div>
    )
}


export default WhyChoose
