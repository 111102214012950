import '../../css/industries.css';
import Nav from '../Nav';
import Footer from '../footer';
import Join from '../about/joinus';
import Platforms from '../marquee_platforms/platforms';
import Industries from '../about/industries';


import imgPath from '../../images/provesyellow.png'


function Telecommunications() {
    const height = {height:'15.5rem'}


    return(
        <div className='main-page'>

        <Nav bgColor='rgb(255, 215, 0);' fontcolor='yellow' imgPath={imgPath}/>
        <div className='about-main'>
            <div className='tele-bg'>
                <br /> <br /> <br /><br /> <br /> <br /> <br /><br />

                <div className='container'>
                    <div className='row'>

                        <div className='col'>
                            <div className='solutions-box'>
                                <h4 className='manufacture-header'>INDUSTRY / TELECOMMUNICATIONS</h4>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className='container'>
                <Platforms />

                <div className='row'>

                </div>

            </div>


            <div className='services-bg'>
                <div className='container'>
                    <div className='row'>

                        <center><h3 className='about-heading'>  <br />What We Do</h3>  <br /><br /></center>

                        <div className='col-lg-4 col-md-6'>
                            <div style={height} className='cards-industries'>
                                <h4 className='about-heading'>
                                Network Optimization
                                </h4>
                                <p className='industry-text'>Enhance network performance and reliability with our telecommunications analytics solutions. We analyze network data to identify issues, optimize capacity, and improve service quality.</p>
                            </div>
                        </div>


                        <div className='col-lg-4 col-md-6'>
                            <div style={height}  className='cards-industries'>
                                <h4 className='about-heading'>Customer Experience</h4>
                                <p className='industry-text'>Improve customer satisfaction and loyalty with our customer analytics services. We analyze customer interactions and feedback to develop targeted retention strategies and enhance service delivery.</p>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6'>
                            <div style={height}  className='cards-industries'>
                                <h4 className='about-heading'>Revenue Assurance</h4>
                                <p className='industry-text'>Maximize revenue and reduce leakage with our revenue assurance solutions. We help telecom companies detect billing errors, fraud, and operational inefficiencies to protect and grow revenue.</p>
                            </div>
                        </div>


                    </div>
                    <br /> <br />
                </div>
            </div>
            <br /> <br /> <br /> <br />
            <Join />
            <br /> <br /> 

            <Industries />

        </div>
        <br /> <br />  <br /> <br />
        <Footer />
    </div>

    )

}

export default Telecommunications


