import '../../css/about.css';
import React, { useState, useEffect } from 'react';

function OurTeam() {

    const colorchange = {color:'white'}


    const [slideIndex, setSlideIndex] = useState(1);

    useEffect(() => {
        showSlides(slideIndex);
    }, [slideIndex]);

    const plusSlides = (n) => {
        setSlideIndex((prevIndex) => {
            let newIndex = prevIndex + n;
            if (newIndex > 3) newIndex = 1;
            if (newIndex < 1) newIndex = 3;
            return newIndex;
        });
    };

    const currentSlide = (n) => {
        setSlideIndex(n);
    };

    const showSlides = (n) => {
        let slides = document.getElementsByClassName("mySlides");
        let dots = document.getElementsByClassName("dot");

        // Hide all slides and remove active class from dots
        for (let i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
            slides[i].classList.remove("fade-in");
        }
        for (let i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(" active", "");
        }

        // Show the selected slide and add active class to corresponding dot
        if (slides[n - 1]) {
            slides[n - 1].style.display = "block";
            setTimeout(() => {
                slides[n - 1].classList.add("fade-in");
            }, 50); // Delay for a small amount to allow transition to work properly
            dots[n - 1].className += " active";
        }
    };
        

    const styles = { textAlign: 'center' };

    const renderText = () => {
        switch (slideIndex) {
            case 1:
                return <div style={colorchange} className='ourteam-header'> <h3> Data Science and Analytics</h3>
                    <br />
                    <h1><i class="bi bi-bar-chart"></i></h1></div>;
            case 2:
                return <div style={colorchange} className='ourteam-header'><h3>Data Engineering and Management</h3> <br />
                    <h1><i class="bi bi-fingerprint"></i> </h1>
                </div>;
            case 3:
                return <div style={colorchange} className='ourteam-header'>
                    <h3>Specialized AI and Technical Expertise</h3>
                    <br />
                    <h1 ><i className="bi bi-robot" ></i></h1>
                </div>;
            default:
                return null;
        }
    };


    return (
        <div className='ourteam-background'>
            <div className='container '>
                <div className='row'>
                    <center>
                        <br /><br /> <br /><br />
                        <h2 style={colorchange} className='about-company-header'>OUR TEAM</h2>
                        <br /><br /><br />
                    </center>


                        <div className='col-1'>    <br /><br /> <br /><br />
                        <i style={colorchange} onClick={() => plusSlides(-1)} class="bi bi-arrow-left arrow"></i>
                          </div>
                          
                    <div className='col-md-4'>
                        <br /><br /> <br /><br />
                        {renderText()}
                    </div>

                    <div className='col-lg-5 col-md-10'>
                        <div className="slideshow-container">
                            <div className="mySlides">
                                <div id='our-team' className='col'>
                                    <div className='cards-ourteam'>
                                        <img className='why-choose' src="https://miro.medium.com/v2/resize:fit:800/1*TPsJDvHA6QldFI7nZi4LFw.png" alt="Integrity" />
                                        <p className='text-ourteam'>At Provez, our dedicated team specializes in Data Science and Analytics, utilizing advanced techniques such as statistical analysis, machine learning, and data visualization. We excel in extracting actionable insights from complex datasets, empowering organizations to make informed decisions and achieve strategic business objectives through data-driven strategies.</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="mySlides">
                                <div id='our-team' className='col'>
                                    <div className='cards-ourteam'>
                                        <img className='why-choose' src="https://cdn.analyticsvidhya.com/wp-content/uploads/2020/02/data-eng..jpg" alt="Integrity" />
                                        <p className='text-ourteam'>In Data Engineering and Management, our specialized team excels in designing robust data architectures, optimizing data pipelines, and implementing efficient data storage solutions. Leveraging deep expertise in database technologies, data integration, and scalable infrastructure, our professionals empower organizations to make data-driven decisions, drive innovation, and maximize the value of their data assets.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="mySlides">
                                <div id='our-team' className='col '>
                                    <div className='cards-ourteam'>
                                        <img className='why-choose' src="https://media.licdn.com/dms/image/C5612AQG7js2nCA9xJg/article-cover_image-shrink_720_1280/0/1520179655168?e=2147483647&v=beta&t=mo1XHo178TjLSadS44zLlWzDJGpvsljX9JUhXTGAkcY" alt="Integrity" />
                                        <p className='text-ourteam'>Within our team, specialized AI and technical expertise drive the development of advanced artificial intelligence models and solutions tailored to specific domains. Leveraging deep knowledge in machine learning algorithms, neural networks, and algorithmic optimization, we innovate to enhance automation, predictive capabilities, and intelligent decision-making, contributing to technological advancement and organizational success.</p>
                                    </div>
                                </div>
                            </div>

                           
                        </div>
                        <br />

                        <div style={styles}>
                            <span className="dot" onClick={() => currentSlide(1)}></span>
                            <span className="dot" onClick={() => currentSlide(2)}></span>
                            <span className="dot" onClick={() => currentSlide(3)}></span>
                        </div>
                    </div>

                    <div className='col'>  <br /><br /> <br /><br />
                    <i style={colorchange} onClick={() => plusSlides(1)} class="bi bi-arrow-right arrow"></i>
                        
                        </div>
                        


                </div>
            </div>
        </div>
    );
}

export default OurTeam;
