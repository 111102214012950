import '../../../../css/solutions.css';
import Nav from '../../../Nav';
import Footer from '../../../footer';
import AnalyticsSolutions from '../data&analyticslist';
import Platforms from '../../../marquee_platforms/platforms';
import Join from '../../../about/joinus';



import imgPath from '../../../../images/provesyellow.png'

function DataAnalytics_BI()  {


    const height = {height:'17rem'}
    return(

        <div className='main-page'>

        <Nav bgColor='rgb(255, 215, 0);' fontcolor='yellow' imgPath={imgPath} />
        <div className='about-main'>
            <div className='data-analytics-bg'>
                <br /> <br /> <br /><br /> <br /> <br /> <br /><br /><br />

                <div className='container'>
                    <div className='row'>

                        <div className='col'>
                            <div className='solutions-box'>
                                <h4>SOLUTIONS / DATA ANALYTICS AND BUSINESS INTELLIGENCE</h4>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className='container'>
                <Platforms />

                <div className='row'>

                    <div className='col-md-6'>
                        <h2 className='about-heading'>Data analytics and business intelligence: illuminating insights for informed decisions and strategic growth.</h2>
                        <br />
                    </div>

                    <div className='col'>
                        <p className='about-company-paragraph'>
                        Data analytics and business intelligence empower organizations by transforming raw data into actionable insights. By leveraging advanced analytical tools and methodologies, businesses can uncover patterns, 
                        trends, and correlations that drive informed decision-making. This capability not only enhances operational efficiency but also enables strategic foresight, positioning companies to adapt and thrive in dynamic market landscapes.
                            <br />  <br />
                          
                        </p>

                    </div>

                </div>

            </div>


            <div className='services-bg'>
                <div className='container'>
                    <div className='row'>

                        <center><h3 className='about-heading'>  <br /><br /><br />Services</h3>  <br /><br /></center>

                        <div className='col-lg-4 col-md-6'>
                            <div style={height} className='cards-industries'>
                                <h4 className='about-heading'>Diagnostic Analytics</h4>
                                <p className='about-company-paragraph'>Gain insights into why certain events occurred through our diagnostic analytics services. We delve deep into your data to uncover the root causes of issues and provide actionable recommendations for improvement.</p>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6'>
                            <div style={height}  className='cards-industries'>
                                <h4 className='about-heading'>Descriptive Analytics</h4>
                                <p className='about-company-paragraph'>Understand what has happened in your business with our descriptive analytics services. We analyze historical data to identify trends, patterns, and anomalies, providing you with a clear picture of past performance.</p>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6'>
                            <div style={height}  className='cards-industries'>
                                <h4 className='about-heading'>Business Intelligence (BI)</h4>
                                <p className='about-company-paragraph'>Empower your organization with our BI solutions. We design and implement interactive dashboards, reports, and visualizations that transform complex data into easily understandable insights. Our BI tools enable you to monitor key performance indicators (KPIs) and make data-driven decisions.</p>
                            </div>
                        </div>

                    </div>
                </div>
                <br />   <br />
            </div>
            <br /> <br /> <br /> <br />
            <Join/>
            <br /> <br /> <br /> <br /> <br /> 

            <AnalyticsSolutions />
         

        </div>
        <Footer />
    </div>

    )


}

export default DataAnalytics_BI