import { useEffect } from 'react';

const AiAssistant = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://widget.cxgenie.ai/widget.js';
    script.setAttribute('data-aid', '440e3524-204f-449d-be6b-5801f282d979');
    script.setAttribute('data-lang', 'en');
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default AiAssistant;