import '../../../css/services/epm.css';
import expert from '../../../images/about/expert.jpg'
import approach from '../../../images/about/approach.webp'
import integrity from '../../../images/about/integrity.jpg'
import innovation from '../../../images/about/innovation.webp'
import solutions from '../../../images/about/solutions.jpg'
import results from '../../../images/about/results.avif'
import support from '../../../images/about/support.jpg'
import methodolgy from '../../../images/about/methodology.png'
import React, { useState, useEffect } from 'react';




function WhyEPM() {

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
  

    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    
    const radius_height = {
      borderRadius: '0px',
      height: isMobile ? '43rem' : '36rem', // Adjust height based on screen size
    };
   
    
    return (
        <div>
            <center ><h3 className='about-heading'>Why Choose Provez for EPM Technology Services ?</h3></center>
            <br />
            <div className='row all'>

                <div className='columns-container'>
                    <div className='col col-all'>
                        <div style={radius_height} className='cards'>
                            <h3 className='about-company-header'>EXPERTISE & EXPERIENCE</h3>
                            <img className='why-choose' src={expert} alt="Expertise" />
                            <p className='text-whychoose'>Our team is composed of certified Oracle EPM professionals with years of experience in implementing and optimizing EPM solutions across various industries. We bring a wealth of knowledge and a proven track record of success to every project, ensuring that you benefit from best practices and cutting-edge techniques.</p>
                        </div>
                    </div>

                    <div className='col col-all'>
                        <div style={radius_height} className='cards'>
                            <h3 className='about-company-header'>CUSTOMIZED SOLUTIONS</h3>
                            <img className='why-choose' src={solutions} alt="Innovation" />
                            <p className='text-whychoose'>We understand that every organization has unique needs and challenges. That’s why we offer tailored EPM solutions that align with your specific business goals and operational requirements. Whether you need help with financial planning, consolidation, reporting, or strategic modeling, we provide personalized services that deliver tangible results.</p>
                        </div>
                    </div>

                    <div className='col col-all'>
                        <div style={radius_height} className='cards'>
                            <h4 className='about-company-header'>COMPREHENSIVE SUPPORT</h4>
                            <img className='why-choose' src={support} alt="Client Centric Approach" />
                            <p className='text-whychoose'>From initial consultation and implementation to ongoing support and optimization, we offer end-to-end services throughout the entire EPM lifecycle. Our goal is to be your trusted partner, providing continuous support and guidance to help you adapt to changing business environments and achieve sustained success.</p>
                        </div>
                    </div>

                    <div className='col col-all'>
                        <div style={radius_height} className='cards'>
                            <h3 className='about-company-header'>PROVEN METHODOLOGY</h3>
                            <img className='why-choose' src={methodolgy} alt="Integrity" />
                            <p className='text-whychoose'>Our implementation methodology is based on industry best practices and a structured approach that minimizes risks and ensures successful project delivery. We focus on delivering value quickly and efficiently, with a clear roadmap and defined milestones to track progress and achieve your objectives.</p>
                        </div>
                    </div>

                    <div className='col col-all'>
                        <div style={radius_height} className='cards'>
                            <h3 className='about-company-header'>INNOVATION & ADAPTABILITY</h3>
                            <img className='why-choose' src={innovation} alt="Integrity" />
                            <p className='text-whychoose'>At Provez, we stay at the forefront of technological advancements in EPM. We continuously explore new methods and tools to enhance our solutions and ensure that you receive the most effective and up-to-date services. Our innovative approach helps you stay ahead of the competition and adapt to evolving market conditions.</p>
                        </div>
                    </div>

                    <div className='col col-all'>
                        <div style={radius_height} className='cards'>
                            <h3 className='about-company-header'>CLIENT CENTRIC APPROACH</h3>
                            <img className='why-choose' src={approach} alt="Integrity" />
                            <p className='text-whychoose'>Your success is our top priority. At Provez, we work closely with you to thoroughly understand your unique challenges and provide tailored solutions that drive meaningful improvements. Our client-centric approach ensures that you receive the highest level of personalized service and dedicated support, fostering long-term relationships built on trust and mutual success.</p>
                        </div>
                    </div>

                    <div className='col col-all'>
                        <div style={radius_height} className='cards'>
                            <h3 className='about-company-header'>TRANSPARENCY & INTEGRITY</h3>
                            <img className='why-choose' src={integrity} alt="Integrity" />
                            <p className='text-whychoose'>We are committed to maintaining the highest standards of transparency and integrity in all our engagements. You can trust us to handle your EPM needs with professionalism, honesty, and a focus on delivering the best possible outcomes for your business.</p>
                        </div>
                    </div>

                    <div className='col col-all'>
                        <div style={radius_height} className='cards'>
                            <h3 className='about-company-header'>MEASURABLE RESULTS & PROVEN SUCCESS</h3>
                            <img className='why-choose' src={results} alt="Integrity" />
                            <p className='text-whychoose'>Our services are designed to deliver measurable improvements in your financial performance and strategic planning. We help you achieve greater accuracy, efficiency, and insight, empowering you to make informed decisions and drive sustained growth.</p>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    )
}


export default WhyEPM
