import '../../../css/services/digitaltransform.css';

import strategy from '../../../images/digitaltransform/strategy.jpg'
import data from '../../../images/digitaltransform/business-data-analysis.jpg'
import technology from '../../../images/digitaltransform/ai-nuclear-energy-background-future-innovation-disruptive-technology.jpg'
import customer from '../../../images/digitaltransform/collage-customer-experience-concept.jpg'
import change from '../../../images/digitaltransform/Change-Management.jpg'


function Approach() {
    return (


        <div className='container'>

            <center> <h2 className='about-company-header'>Our Approach to Digital Transformation</h2></center>
            <br /><br /><br /><br />

            {/* First Row */}
            <div className='row'>

                <div className='col-1'></div>

                <div data-aos="fade-in" data-aos-easing="ease-in-out-quad" data-aos-duration="2800" className='col-0_5'><h3 className='epm-number'>01</h3></div>

                <div data-aos="fade-right" data-aos-easing="ease-in-out-quad" data-aos-duration="1800" className='col-md-4'>

                <h4 className='about-heading'>Strategy Development</h4>


                    <ul className='about-company-paragraph point-change'>
                        <li>
                            <span className='heading-bold'>Assessment and Roadmap:</span> We begin by assessing your current digital capabilities and identifying opportunities for growth. Our team works closely
                            with you to develop a clear, actionable digital transformation roadmap tailored to your business goals.
                        </li>

                        <li><span className='heading-bold'>Innovation Workshops:</span> We conduct workshops to foster innovative thinking and help you explore new business models and revenue streams enabled by digital technologies.</li>
                    </ul>

                </div>

                <div className='col-1'></div>

                <div className='col-md-4'>

                    <div className="image-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="1800">
                        <img className="oracle-images" src={strategy} />
                    </div>
                </div>


            </div>


            <br /><br /><br /><br />


            {/* Second Row */}
            <div className='row'>

                <div className='col-1'></div>

                <div data-aos="fade-in" data-aos-easing="ease-in-out-quad" data-aos-duration="2800" className='col-0_5'><h3 className='epm-number'>02</h3></div>

                <div data-aos="fade-right" data-aos-easing="ease-in-out-quad" data-aos-duration="1800" className='col-md-4'>

                    <h4 className='about-heading'>Data-Driven Decision Making</h4>



                    <ul className='about-company-paragraph point-change'>
                        <li>
                            <span className='heading-bold'>Data Integration and Management:</span>  We ensure seamless integration and management of data across your organization, breaking down silos and enabling a unified view of your business operations.
                        </li>

                        <li><span className='heading-bold'>Advanced Analytics:</span> Our advanced analytics solutions provide deep insights into your data, helping you make informed decisions, optimize processes, and predict future trends.</li>
                    </ul>

                </div>

                <div className='col-1'></div>

                <div className='col-md-4'>

                    <div className="image-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="1800">
                        <img className="oracle-images" src={data} />
                    </div>
                </div>

            </div>


            <br /><br /><br /><br />


            {/* Third Row */}
            <div className='row'>

                <div className='col-1'></div>

                <div data-aos="fade-in" data-aos-easing="ease-in-out-quad" data-aos-duration="2800" className='col-0_5'><h3 className='epm-number'>03</h3></div>

                <div data-aos="fade-right" data-aos-easing="ease-in-out-quad" data-aos-duration="1800" className='col-md-4'>

                    <h4 className='about-heading'>Technology Enablement</h4>



                    <ul className='about-company-paragraph point-change'>
                        <li>
                            <span className='heading-bold'>Cloud Solutions:</span> We leverage cloud computing to enhance scalability, flexibility, and efficiency in your operations. Our cloud solutions support a wide range of applications, from data storage and processing to AI and machine learning.
                        </li>

                        <li><span className='heading-bold'>AI and Automation:</span> By incorporating AI and automation into your workflows, we help you streamline operations, reduce costs, and improve accuracy. Our solutions include intelligent process automation, chatbots, and predictive analytics.</li>
                    </ul>

                </div>

                <div className='col-1'></div>

                <div className='col-md-4'>

                    <div className="image-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="1800">
                        <img className="oracle-images" src={technology} />
                    </div>
                </div>

            </div>




            <br /><br /><br /><br />


            {/* Forth Row */}
            <div className='row'>

                <div className='col-1'></div>

                <div data-aos="fade-in" data-aos-easing="ease-in-out-quad" data-aos-duration="2800" className='col-0_5'><h3 className='epm-number'>04</h3></div>

                <div data-aos="fade-right" data-aos-easing="ease-in-out-quad" data-aos-duration="1800" className='col-md-4'>

                    <h4 className='about-heading'>Customer Experience Transformation</h4>



                    <ul className='about-company-paragraph point-change'>
                        <li>
                            <span className='heading-bold'>Omnichannel Engagement:</span> We help you create a seamless and consistent customer experience across all digital and physical touchpoints. Our solutions include personalized marketing, customer journey mapping, and experience optimization.

                        </li>

                        <li><span className='heading-bold'>Customer Insights: </span> Through advanced analytics, we provide deep insights into customer behavior and preferences, enabling you to tailor your offerings and enhance customer satisfaction.</li>
                    </ul>

                </div>

                <div className='col-1'></div>

                <div className='col-md-4'>

                    <div className="image-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="1800">
                        <img className="oracle-images" src={customer} />
                    </div>
                </div>

            </div>


            <br /><br /><br /><br />


            {/* Fifth Row */}
            <div className='row'>

                <div className='col-1'></div>

                <div data-aos="fade-in" data-aos-easing="ease-in-out-quad" data-aos-duration="2800" className='col-0_5'><h3 className='epm-number'>05</h3></div>

                <div data-aos="fade-right" data-aos-easing="ease-in-out-quad" data-aos-duration="1800" className='col-md-4'>

                    <h4 className='about-heading'>Change Management</h4>



                    <ul className='about-company-paragraph point-change'>
                        <li>
                            <span className='heading-bold'>Culture and Training:</span> Successful digital transformation requires a cultural shift. We offer training programs and change management strategies to foster a digital-first mindset within your organization.

                        </li>

                        <li><span className='heading-bold'>Continuous Improvement:</span> Digital transformation is an ongoing journey. We provide continuous support and optimization to ensure your digital initiatives remain aligned with your evolving business needs.</li>
                    </ul>

                </div>

                <div className='col-1'></div>

                <div className='col-md-4'>

                    <div className="image-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="1800">
                        <img className="oracle-images" src={change} />
                    </div>
                </div>

            </div>


        </div>
    )
}

export default Approach

