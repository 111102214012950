

import { Link } from 'react-router-dom';


function GetStartToday()   {

    return(
        <div className='container back-color'>
        <div className='row'>
            <div className='col-12'>
                <div className='content-box'>
                    <center>
                        <br /><br /> <br /><br />
                        <h2 className='about-company-header'>Get Started Today</h2>
                        <br />
                    </center>
                    <div className='row'>
                        <div className='col-2'></div>
                        <div className='col-md-6'>
                            <h6 className='about-company-header'>
                            Ready to embark on your digital transformation journey? Contact us to schedule a consultation 
                            and discover how Provez can help you unlock new opportunities and achieve sustainable growth in the digital era.
                            </h6>
                        </div>
                        <div className='col'>
                            <br/>
                           <Link to='/contact'> <button className='read-more'>Join Us Today</button></Link>
                        </div>
                        
                    </div>
                </div>
                <br /><br /> <br /><br />
            </div>
        </div>
    </div>
    )
}


export default GetStartToday
