

// Images
import openai from '../../images/genai/key-players/openai.png'
import azure from '../../images/genai/key-players/azure.webp'
import google from '../../images/genai/key-players/google-ai.png'
import ibm from '../../images/genai/key-players/ibm-watson.png'
import adobe from '../../images/genai/key-players/adobe.png'



function KeyPlayers() {



    return (
        <div className="container-fluid key-plyaer-bg">
               <br/>   <br/>
            <center ><h3 className='about-heading'>Key Players and Platforms &nbsp;✨ </h3></center>
            <br /> <br />

            <div className="row">

                <div className="col-1"></div>
            
                <div className="col col-img"><img data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="500"  className="keyplayers" src={openai} /> </div>

                <div className="col">    <img data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="500" data-aos-delay="300" className="keyplayers" src={adobe}/>  </div>
                <div className="col">       <img data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="500" data-aos-delay="400" className="keyplayers" src={azure} /></div>
                <div className="col">       <img data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="500" data-aos-delay="500" className="keyplayers" src={ibm} /></div>
                
                <div className="col"> <img data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="500" data-aos-delay="600" className="keyplayers" src={google} /> </div>

            </div>
            <br/>   <br/>

        </div>
    )
}


export default KeyPlayers