import '../../../../css/services/epm.css';
import Nav from '../../../Nav';
import Footer from '../../../footer';
import EPMLIST from '../epmserviceslist';
import Join from '../../../about/joinus';


//  images
import uniform from '../../../../images/epmlist/epmservices/onestream/uniform.png'
import finance from '../../../../images/epmlist/epmservices/onestream/finance.png'
import imgPath from '../../../../images/proveslogo(1).png'


function Onestream() {
    return (
        <div className='main-page'>
            <Nav bgColor='rgb(255, 215, 0);' fontcolor='orange' imgPath={imgPath}/>

            <div className='about-main'>
                <div className="onestream-image">
                    <br /> <br /> <br /><br /> <br /> <br /> <br /><br />

                    <div className='container'>
                        <div className='row'>

                            <div className='col'>
                                <div className='solutions-box'>
                                    <h4 className='about-heading'>EPM / OneStream XF</h4>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>



                <br /> <br /> <br /> <br /> <br />


                <div className='container'>
                    {/* First Row */}
                    <div className='row'>


                        <div className='col-1'></div>

                        <div data-aos="fade-in" data-aos-easing="ease-in-out-quad" data-aos-duration="2800" className='col-0_5'><h3 className='epm-number'>01</h3></div>

                        <div data-aos="fade-right" data-aos-easing="ease-in-out-quad" data-aos-duration="1800" className='col-md-4'>

                            <h4 className='about-heading'>Unified CPM Platform</h4>
                            <p className='about-company-paragraph'>OneStream XF provides a unified Corporate Performance Management (CPM) platform for financial consolidation, planning, reporting, and analytics. Our implementation and support services ensure you leverage
                                the full potential of OneStream XF to simplify and unify your financial processes.</p>

                        </div>

                        <div className='col-1'></div>


                        <div className='col-md-4'>

                            <div className="image-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="1800">
                                <img className="oracle-images" src={uniform} />
                            </div>
                        </div>

                    </div>

                    <br /><br />  <br /><br />


                    {/* Second Row */}
                    <div className='row'>


                        <div className='col-1'></div>

                        <div data-aos="fade-in" data-aos-easing="ease-in-out-quad" data-aos-duration="2800" className='col-0_5'><h3 className='epm-number'>01</h3></div>

                        <div data-aos="fade-right" data-aos-easing="ease-in-out-quad" data-aos-duration="1800" className='col-md-4'>

                            <h4 className='about-heading'>Financial Close and Consolidation</h4>
                            <p className='about-company-paragraph'>Accelerate your financial close and consolidation processes with OneStream XF. We help you automate and streamline complex 
                                financial tasks, ensuring accuracy and compliance while reducing the time and effort required for financial close.</p>

                        </div>

                        <div className='col-1'></div>


                        <div className='col-md-4'>

                            <div className="image-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="1800">
                                <img className="oracle-images" src={finance} />
                            </div>
                        </div>

                    </div>

                    <br /><br />  <br /><br />

                </div>


                <br /><br />
                <EPMLIST />
                <br /><br />
                <Join />
                <br /><br /><br /><br />
            </div>
            <Footer />
        </div>
    )
}


export default Onestream
