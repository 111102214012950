import React from "react";
import '../css/services.css';
import useAOS from '../hook/serviceAOS';
import { Link } from "react-router-dom";

import performance from '../images/epm-main-images/epm-main.jpeg'
import analytics from '../images/data-analytics-main-images/medium-shot-man-wearing-vr-glasses.jpg'
import dgtransform from '../images/digitaltransform/Vector_2652.jpg'



function Services() {
    useAOS();
    return (
        <div className="container">
            <center><h4 className="about-heading">OUR SERVICES</h4></center>
            <br /> <br /> <br /> <br />

            <div className="containers">
                <div className="row">
                    <div className="col-lg-4 col-md-6 complete-card">
                        <div className="card h-100 custom-card">
                            <img loading="lazy"
                                src={performance}
                                className="card-img-top custom-img"
                                alt="Sample"
                            />
                            <div className="card-body">
                                <h5 className="card-title">ENTERPRISE PERFORMANCE MANAGEMENT</h5>
                                <p className="card-text">
                                    Choosing the right partner for your Enterprise Performance Management (EPM) needs is crucial for achieving financial excellence and strategic growth. At Provez,
                                    we are dedicated to providing unparalleled Oracle EPM services that help you transform your financial processes and drive better business outcomes.
                                </p>
                                <Link to='/epm-services'> <button className="read-more">Read More</button></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 complete-card">
                        <div className="card h-100 custom-card">
                            <img loading="lazy"
                                src={analytics}
                                className="card-img-top custom-img"
                                alt="Sample"
                            />
                            <div className="card-body">
                                <h5 className="card-title">DATA AND ANALYTICS</h5>
                                <p className="card-text">
                                We provide a comprehensive range of advanced analytics services designed to help organizations unlock the full potential of their data. Our expert team leverages cutting-edge analytics,
                                 machine learning, and artificial intelligence to transform raw data into actionable business insights, enabling you to make informed strategic decisions and drive business growth.
                                </p>
                                <Link to='/data-analytics'>   <button className="read-more">Read More</button></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 complete-card">
                        <div className="card h-100 custom-card">
                            <img loading="lazy"
                                src={dgtransform}
                                className="card-img-top custom-img"
                                alt="Sample"
                            />
                            <div className="card-body">
                                <h5 className="card-title">DIGITAL TRANSFORMATION</h5>
                                <p className="card-text">
                                    At Provez, we drive digital transformation across diverse industries. Using cutting-edge technologies and advanced analytics, we help businesses achieve operational excellence, enhance customer
                                    experiences, and create new value propositions through agile, scalable, and industry-tailored digital strategies that consistently deliver measurable results.
                                </p>
                                <Link to='/digital-transformation-service'>  <button className="read-more">Read More</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br />  <br />  <br />  <br /> <br /> <br /> <br />

        </div>
    )
}

export default Services




