import Nav from "../../Nav"
import Footer from "../../footer"
import '../../../css/services/epm.css';
import Platforms from '../../marquee_platforms/platforms';
import Join from "../../about/joinus";
import EPMLIST from "./epmserviceslist";
import WhyEPM from "./whychooseepm";
import imgPath from '../../../images/provesyellow.png';


function EPM() {
    const width = { fontSize: '2.1rem' }

    return (
        <div className='main-page'>
            <Nav bgColor='#000000a8' imgPath={imgPath} />
            <div className='about-main'>
                <div className="epm-image">
                    <br /> <br /> <br /><br /> <br /> <br /> <br /><br /><br />
                    <div className='container'>
                        <div className='row'>
                            <div className='col'>

                                <h5 className='contact-heading'>ENTERPRISE PERFORMANCE MANAGEMENT</h5>
                            </div>
                            <div className='col'></div>
                        </div>
                    </div>

                </div>
                <div className='container'>
                    <Platforms />

                    <div className='row'>

                        <div className='col-md-6'>
                            <h2 className='about-heading'>Transforming Data into Strategic Insights</h2>
                            <br />
                        </div>

                        <div className='col'>
                            <p className='industries-readmore'>
                                Choosing the right partner for your Enterprise Performance Management (EPM) needs is crucial
                                for achieving financial excellence and strategic growth. At Provez, we are dedicated to providing unparalleled Oracle EPM services that help you transform your financial processes and drive better business outcomes.
                                <br />  <br />

                            </p>
                            <br /><br /><br /><br />

                        </div>



                        <h4 className='whatishead'></h4>

                    </div>   <br /><br /><br /><br />
                    <WhyEPM />

                    <br /><br /><br /><br />

                    <h4 className='whatishead'></h4>
                    <br /><br /><br /><br />

                </div>

                <div className="row">
                    <EPMLIST />

                </div>



                <br /><br />  <br /><br />

                <Join />
                <br /><br />  <br /><br />




            </div>


            <Footer />

        </div>
    )
}


export default EPM
