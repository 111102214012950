


function KeyUses() {

    const height = { height: '13rem' }
    const width = {width:'3rem'}

    return (

        <div className='services-bg'>
            <div className='container'>
            <br/><br/><br/>
                <div className='row'>
                   

                <div className="same-line-img"><h4 className='about-heading'>Key Uses Cases &nbsp;✨ <br/><br/> </h4> </div>
              

                    <div className='col-lg-4 col-md-6'>
                        <div style={height} className='cards-industries'>
                            <h4 className='about-heading'>
                            Content Generation <img style={width} src='https://w7.pngwing.com/pngs/501/938/png-transparent-palette-painting-hand-drawn-cartoon-plate-watercolor-painting-cartoon-character-food.png'/>
                            </h4>
                            <p className='industry-text'>Automated creation of text, images, videos, and music for marketing, entertainment, and communication.</p>
                        </div>
                    </div>


                    <div className='col-lg-4 col-md-6'>
                        <div style={height} className='cards-industries'>
                            <h4 className='about-heading'>
                            Personalized Marketing <img style={width} src='https://ouch-cdn2.icons8.com/OrkZhIl-JjbVhrq2fwMHukqHNk9JRC7XJBefmw8wy8I/rs:fit:456:456/extend:true/wm:1:re:0:0:0.8/wmid:ouch/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9zdmcvODEx/LzEzZWE4NzFjLTBl/MDItNDY1NC1hNzE4/LTkzNTUzMmFmMjNi/Ny5zdmc.png'/>

                            </h4>
                            <p className='industry-text'>Developing AI-driven personalization strategies to deliver targeted marketing campaigns and improve customer engagement.</p>
                        </div>
                    </div>

                    <div className='col-lg-4 col-md-6'>
                        <div style={height} className='cards-industries'>
                            <h4 className='about-heading'> Predictive Analytics  <img style={width} src='https://png.pngtree.com/png-vector/20220706/ourmid/pngtree-stats-business-graph-png-image_5705173.png'/> </h4>
                            <p className='industry-text'>Utilizing generative models to predict trends, customer behavior, and market dynamics.</p>
                        </div>
                    </div>

                    <div className='col-lg-4 col-md-6'>
                        <div style={height} className='cards-industries'>
                            <h4 className='about-heading'> Healthcare <img style={width} src='https://t4.ftcdn.net/jpg/05/52/69/57/360_F_552695735_INapeIqxVtUtmGRbrbX8KpNafdJJ65uh.jpg'/></h4>
                            <p className='industry-text'>Generating synthetic medical data for research, enhancing diagnostic tools, and personalizing treatment plans.</p>
                        </div>
                    </div>

                    <div className='col-lg-4 col-md-6'>
                        <div style={height} className='cards-industries'>
                            <h4 className='about-heading'>  Finance <img style={width} src='https://img.lovepik.com/free-png/20210923/lovepik-financial-investment-png-image_401202671_wh1200.png'/>  </h4>
                            <p className='industry-text'> Automating financial reporting, fraud detection, and generating predictive insights for investment strategies.</p>
                        </div>
                    </div>

                </div>
                <br />  <br />
            </div>
        </div>
    )
}

export default KeyUses