import '../../../../css/solutions.css';
import Nav from '../../../Nav';
import Footer from '../../../footer';
import AnalyticsSolutions from '../data&analyticslist';
import Platforms from '../../../marquee_platforms/platforms';
import Join from '../../../about/joinus';



import imgPath from '../../../../images/provesyellow.png'

function Consulting()  {
    const height = {height:'15rem'}

    return(

      
        <div className='main-page'>

            <Nav  bgColor='rgb(255, 215, 0);' fontcolor='yellow' imgPath={imgPath} />
            <div className='about-main'>
                <div className='consulting-strategy-bg'>
                    <br /> <br /> <br /><br /> <br /> <br /> <br /><br /><br />

                    <div className='container'>
                        <div className='row'>

                            <div className='col'>
                                <div className='solutions-box'>
                                    <h4>SOLUTIONS / CONSULTING & STRATEGY </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='container'>
                    <Platforms />

                    <div className='row'>

                        <div className='col-md-6'>
                            <h2 className='about-heading'>Transformative Power of Digital Consulting</h2>
                            <br />
                        </div>

                        <div className='col'>
                            <p className='about-company-paragraph'>
                            We offer strategic digital consulting services, delivering tailored guidance, customized solutions,
                             and ongoing support to help organizations maximize their data assets. In today's rapidly evolving digital landscape, 
                             expert consultancy is crucial for navigating technological complexities, harnessing data insights effectively, and implementing scalable solutions that drive innovation and competitive advantage
                                <br />  <br />
                                
                            </p>

                        </div>

                    </div>

                </div>


                <div className='services-bg'>
                    <div className='container'>
                        <div className='row'>

                            <center><h3 className='about-heading'>  <br /><br /><br />Services</h3>  <br /><br /></center>

                            <div className='col-lg-4 col-md-6'>
                                <div style={height} className='cards-industries'>
                                    <h4 className='about-heading'>Data Strategy Development</h4>
                                    <p className='about-company-paragraph'>Develop a comprehensive data strategy that aligns with your business goals. We work with you to define your data vision, set objectives, and create a roadmap for achieving them.</p>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6'>
                                <div style={height} className='cards-industries'>
                                    <h4 className='about-heading'>Data Governance</h4>
                                    <p className='about-company-paragraph'>Implement robust data governance frameworks to ensure data quality, security, and compliance. Our services help you establish policies, procedures, and controls to manage your data assets effectively</p>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6'>
                                <div style={height} className='cards-industries'>
                                    <h4 className='about-heading'>Data Transformation</h4>
                                    <p className='about-company-paragraph'>Transform your organization into a data-driven enterprise with our data transformation services. We assist you in adopting the right technologies, processes, and culture to maximize the value of your data.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <br />   <br />
                </div>
                
                <br /> <br /> <br /> <br />
                <Join />
                <br /> <br /> <br /> <br /> <br />

                <AnalyticsSolutions />

            </div>
            <Footer />
        </div>


    )


}

export default Consulting