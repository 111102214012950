import '../css/footer.css';
import imgPath from '../images/provesyellow.png';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer'>
      <footer className="bg-dark text-white text-center text-lg-start">
        <div className="container p-4">
          <div className="row">
            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
              <h5 className='footer-align'>Services</h5>
              <ul className="list-unstyled mb-0">
                <li>
                  <Link to='/epm-services'><p className='text-solution'>EPM</p></Link>
                </li>
                <li>
                  <Link to='/data-analytics'><p className='text-solution'>Data & Analytics</p></Link>
                </li>
                <li>
                  <Link to='/digital-transformation-service'><p className='text-solution'>Digital Transformation</p></Link>
                </li>
                <li>
                  <Link to='/staffing-augmentation-service'><p className='text-solution'>Staffing Augmentation Services</p></Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
              <h5>Analytics Solutions</h5>
              <ul className="list-unstyled">
                <li>
                  <Link to='/machine-learning-ai-service'><p className="text-solution">AI & ML</p></Link>
                </li>
                <li>
                  <Link to='/bigdata-service'><p className="text-solution">Big Data</p></Link>
                </li>
                <li>
                  <Link to='/data-analytics-bi-service'><p className="text-solution">Data Analytics & BI</p></Link>
                </li>
                <li>
                  <Link to='/predictive-analytics-service'><p className="text-solution">Predictive Analytics</p></Link>
                </li>
                <li>
                  <Link to='/prescriptive-analytics-service'><p className="text-solution">Prescriptive Analytics</p></Link>
                </li>
                <li>
                  <Link to='/consulting-service'><p className="text-solution">Consulting and Strategy</p></Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
              <img className='footer-img' src={imgPath} alt="Provez Logo" />
              <p className='footer-location'>
                Provez Heuristics Private Limited<br />
                WeWork Cherry Hills, 4th Floor<br />
                Embassy Golf Links Software Park<br />
                Bangalore, Karnataka - 560071<br />
                India
              </p>
            </div>

            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
              <p className='footer-location'>
                Provez Analytics FZ LLC<br />
                DIC Building, Al Sufouh<br />
                Dubai Internet City<br />
                Dubai-73000<br />
                United Arab Emirates
              </p>
              <p className='footer-location'>
                Provez Inc<br />
                1007 N Orange St.<br />
                4th Floor, Suite #3717<br />
                Wilmington, Delaware 19801<br />
                United States
              </p>
            </div>
          </div>
        </div>

        <div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
          © 2024 Copyright:
          <a className="text-white" href="#">Provez</a>
        </div>
      </footer>
    </div>
  )
}

export default Footer;
