import '../../../../css/services/epm.css';
import Nav from '../../../Nav';
import Footer from '../../../footer';
import EPMLIST from '../epmserviceslist';
import Join from '../../../about/joinus';

//  images
import business from '../../../../images/epmlist/epmservices/insights/business-insights.jpg'
import imgPath from '../../../../images/provesyellow.png'


function AdaptInsights() {
    return(
        <div className='main-page'>
        <Nav bgColor='rgb(255, 215, 0);' fontcolor='yellow' imgPath={imgPath}/>


        <div className='about-main'>
            <div className="insights-image">
                <br /> <br /> <br /><br /> <br /> <br /> <br /><br />

                <div className='container'>
                    <div className='row'>

                        <div className='col'>
                            <div className='solutions-box'>
                                <h4 className='contact-heading'>EPM / ADAPTIVE INSIGHTS</h4>

                            </div>
                        </div>
                    </div>
                </div>

            </div>



            <br /> <br /> <br /> <br /> <br />


            <div className='container'>
                {/* First Row */}
                <div className='row'>


                    <div className='col-1'></div>

                    <div data-aos="fade-in" data-aos-easing="ease-in-out-quad" data-aos-duration="2800" className='col-0_5'><h3 className='epm-number'>01</h3></div>

                    <div data-aos="fade-right" data-aos-easing="ease-in-out-quad" data-aos-duration="1800" className='col-md-4'>

                        <h4 className='about-heading'>Business Planning Cloud</h4>
                        <p className='about-company-paragraph'>Adaptive Insights provides a powerful cloud-based planning solution that supports continuous and comprehensive planning across your organization. 
                            Our services include implementation, integration, and optimization to help you build adaptive models and drive strategic agility.</p>

                    </div>

                    <div className='col-1'></div>


                    <div className='col-md-4'>

                        <div className="image-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="1800">
                            <img className="oracle-images" src={business}/>
                        </div>
                    </div>

                </div>

                <br /><br />  <br /><br />

            </div>


            <br /><br />
            <EPMLIST />
            <br /><br />
            <Join />
            <br /><br /><br /><br />
        </div>
        <Footer />
    </div>
    )
}


export  default AdaptInsights