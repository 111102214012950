import '../../css/industries.css';
import Nav from '../Nav';
import Footer from '../footer';
import Join from '../about/joinus';
import Platforms from '../marquee_platforms/platforms';
import Industries from '../about/industries';

import imgPath from '../../images/proveslogo(1).png'


function Energy() {

    const height = { height: 'auto' }

    return (

        <div className='main-page'>

            <Nav bgColor='rgb(255, 215, 0);' fontcolor='black' imgPath={imgPath} />
            <div className='about-main'>
                <div className='energy-bg'>
                    <br /> <br /> <br /><br /> <br /> <br /> <br /><br />

                    <div className='container'>
                        <div className='row'>

                            <div className='col'>
                                <div className='solutions-box'>
                                    <h4 className='finance-header'>INDUSTRY / ENERGY</h4>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='container'>
                    <Platforms />

                    <div className='row'>

                    </div>

                </div>


                <div className='services-bg'>
                    <div className='container'>
                        <div className='row'>

                            <center><h3 className='about-heading'>  <br/>What We Do</h3>  <br /><br /></center>

                            <div className='col-lg-4 col-md-6'>
                                <div style={height} className='cards-industries'>
                                    <h4 className='about-heading'>
                                    Energy Management
                                    </h4>
                                    <p className='industry-text'>Optimize energy consumption and reduce costs with our advanced energy analytics solutions. We help utility companies analyze consumption patterns, forecast demand, identify inefficiencies, and develop effective energy-saving strategies.</p>
                                </div>
                            </div>


                            <div className='col-lg-4 col-md-6'>
                                <div style={height} className='cards-industries'>
                                    <h4 className='about-heading'>
                                    Asset Management

                                    </h4>
                                    <p className='industry-text'>Improve asset performance and lifespan with our predictive maintenance and asset management solutions. We provide insights into equipment health and performance to support proactive maintenance and asset optimization.</p>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6'>
                                <div style={height} className='cards-industries'>
                                    <h4 className='about-heading'>Regulatory Compliance</h4>
                                    <p className='industry-text'>Ensure compliance with industry regulations and standards through our comprehensive analytics services. We help energy and utility companies monitor compliance metrics, report accurately, and mitigate regulatory risks.</p>
                                </div>
                            </div>
                        </div>
                        <br/>  <br/>
                    </div>
                </div>
                <br /> <br /> <br /> <br />
                <Join />
                <br /> <br />

                <Industries />


            </div>
            <br /> <br />  <br /> <br />
            <Footer />
        </div>
    )
}

export default Energy
