

import { Link } from 'react-router-dom';


function GetStartStaff()    {
    
    return(
        <div className='container back-color'>
        <div className='row'>
            <div className='col-12'>
                <div className='content-box'>
                    <center>
                        <br /><br /> <br /><br />
                        <h2 className='about-company-header'>Get Started Today</h2>
                        <br />
                    </center>
                    <div className='row'>
                        <div className='col-2'></div>
                        <div className='col-md-6'>
                            <h6 className='about-company-header'>
                            Ready to enhance your team with specialized talent? Contact us to learn more about how our staffing 
                            augmentation services can support your business needs and drive your projects to success.
                            </h6>
                        </div>
                        <div className='col'>
                            <br/>
                           <Link to='/contact'> <button className='read-more'>Join Us Today</button></Link>
                        </div>
                        
                    </div>
                </div>
                <br /><br /> <br /><br />
            </div>
        </div>
    </div>
    )
}

export default GetStartStaff