import '../../css/about.css';
import { Link } from 'react-router-dom';




function Industries() {


    const height = {height:'auto'}

    return (

        <div className=''>

            <div className='container'>
                <div className='row'>
                    <center>
                        <br /><br /> <br /><br />
                        <h2 className='about-company-header'>Industry Expertise and
                            Solutions</h2>
                        <br />
                        
                    </center>
                    <p  className='industry-text'>At Provez, we understand that each industry has unique challenges and opportunities.
                         Our tailored analytics solutions are designed to address the specific needs of various sectors,
                          helping organizations unlock the full potential of their data and drive success. <br /><br /></p>
                         

                    <div className='col-lg-4 col-md-6'>
                    <Link to='/industries-banking'>   <div style={height} className='cards-industries'>
                       <p>  <i class="bi bi-patch-check-fill"></i> Finace & Banking</p>
                       <h6 data-aos="zoom-in" data-aos-easing="ease-in-out-quad" data-aos-duration="1800"  className='industries-readmore'>Transform your financial strategies with our comprehensive analytics solutions. From mitigating risks and enhancing customer 
                        insights to optimizing performance, we empower banks and financial institutions with actionable data-driven strategies for sustainable growth and compliance. <span className='industries-learnmore'>Learn More</span></h6>
                       
                        </div></Link>
                    </div>

                    <div className='col-lg-4 col-md-6'>
                    <Link to='/healthcare'>   <div style={height} className='cards-industries'>
                       <p> <i class="bi bi-patch-check-fill"></i> Healthcare & Life Sciences</p>
                       <h6 data-aos="zoom-in" data-aos-easing="ease-in-out-quad" data-aos-duration="1800"  className='industries-readmore'>Improve patient outcomes and operational efficiency with our healthcare analytics solutions. Accelerate drug discovery and clinical trials with advanced analytics
                         and machine learning. Enhance operational efficiency and resource allocation with data-driven insights. <span className='industries-learnmore'>Learn More</span></h6>
                       
                        </div></Link>
                    </div>

                    <div className='col-lg-4 col-md-6'>
                    <Link to='/retail'>  <div style={height} className='cards-industries'>
                          <p> <i class="bi bi-patch-check-fill"></i> Retail & eCommerce</p>
                          <h6 data-aos="zoom-in" data-aos-easing="ease-in-out-quad" data-aos-duration="1800"  className='industries-readmore'>Enhance retail operations with our analytics solutions. We analyze customer behavior, market trends, and inventory data to optimize product offerings and 
                            streamline supply chain operations. Our services also include sales analytics to maximize profitability and refine marketing strategies for sustained growth. <span className='industries-learnmore'>Learn More</span></h6>
                        </div></Link>
                    </div>

                    <div className='col-lg-4 col-md-6'>
                    <Link to='/manufacturing'> <div style={height} className='cards-industries'>
                            <p>    <i class="bi bi-patch-check-fill"></i> Manufacturing</p>
                            <h6 data-aos="zoom-in" data-aos-easing="ease-in-out-quad" data-aos-duration="1800"  className='industries-readmore'> Predict equipment failures and lower maintenance costs with our predictive maintenance solutions. Enhance product quality by monitoring production processes and implementing corrective actions using our quality control analytics. <span className='industries-learnmore'>Learn More</span></h6>
                        </div></Link>
                    </div>

                    <div className='col-lg-4 col-md-6'>
                    <Link to='/energy'>   <div style={height} className='cards-industries'>
                            <p>    <i class="bi bi-patch-check-fill"></i> Energy and Utilities</p>
                            <h6 data-aos="zoom-in" data-aos-easing="ease-in-out-quad" data-aos-duration="1800"  className='industries-readmore'> Optimize energy consumption and reduce costs with our energy analytics solutions for utility companies. Improve asset performance and lifespan with predictive maintenance and asset management insights. <span className='industries-learnmore'>Learn More</span></h6>
                        </div></Link>
                    </div>

                    <div className='col-lg-4 col-md-6'>
                    <Link to='/telecommunications'>  <div style={height} className='cards-industries'>
                            <p>     <i class="bi bi-patch-check-fill"></i>  Telecommunications</p>
                            <h6 data-aos="zoom-in" data-aos-easing="ease-in-out-quad" data-aos-duration="1800"  className='industries-readmore'> Enhance telecommunications network performance and reliability with our analytics solutions. Improve customer satisfaction and loyalty through detailed customer analytics, developing targeted retention strategies. <span className='industries-learnmore'>Learn More</span></h6>
                        </div></Link>
                    </div>

                    <div className='col-lg-4 col-md-6'>
                    <Link to='/sector'>   <div style={height} className='cards-industries'>
                            <p>    <i class="bi bi-patch-check-fill"></i> Government and Public Sector</p>
                            <h6 data-aos="zoom-in" data-aos-easing="ease-in-out-quad" data-aos-duration="1800"  className='industries-readmore'>Support evidence-based decision-making with our policy and program analysis services for government agencies. Enhance public safety and security through advanced analytics, aiding law enforcement in crime analysis and resource allocation. <span className='industries-learnmore'>Learn More</span></h6>
                        </div></Link>
                    </div>

                </div>

            </div>

        </div>

    )
}


export default Industries
