import '../../css/industries.css';
import Nav from '../Nav';
import Footer from '../footer';
import Join from '../about/joinus';
import Platforms from '../marquee_platforms/platforms';
import Industries from '../about/industries';


import imgPath from '../../images/proveslogo(1).png'

function Banking() {

    const width = {fontSize:'1.4rem'}
    return (

        <div className='main-page'>

            <Nav bgColor='rgb(255, 215, 0);' fontcolor='black' imgPath={imgPath}/>
            <div className='about-main'>
                <div className='finance-banking-bg'>
                    <br /> <br /> <br /><br /> <br /> <br /> <br /><br />

                    <div className='container'>
                        <div className='row'>

                            <div className='col'>
                                <div className='solutions-box'>
                                    <h4 className='finance-header'>INDUSTRY / FINANCE & BANKING</h4>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='container'>
                    <Platforms />

                    <div className='row'>


                    </div>

                </div>


                <div className='services-bg'>
                    <div className='container'>
                        <div className='row'>

                            <center><h3 className='about-heading'>  <br />What We Do</h3>  <br /><br /></center>

                            <div className='col-lg-4 col-md-6'>
                                <div className='cards-industries'>
                                    <h4 className='about-heading'>Risk Management</h4>
                                    <p className='industry-text'>Mitigate financial risks with our advanced analytics solutions. We help banks and financial institutions assess credit risk, detect fraud, and ensure regulatory compliance through predictive modeling and real-time monitoring.</p>
                                </div>
                            </div>

                          
                            <div className='col-lg-4 col-md-6'>
                                <div className='cards-industries'>
                                    <h4 className='about-heading'>Customer Insights</h4>
                                    <p className='industry-text'>Enhance customer experience and loyalty by leveraging our customer analytics services. We analyze customer behavior and preferences to develop targeted marketing strategies, optimize product offerings, and improve customer retention. </p>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6'>
                                <div className='cards-industries'>
                                    <h4 style={width} className='about-heading'>Performance Optimization</h4>
                                    <p className='industry-text'>Optimize financial performance and operational efficiency with our analytics services. We provide insights into key performance indicators (KPIs), streamline financial planning and budgeting, and support strategic decision-making.</p>
                                </div>
                                <br /><br />
                            </div>

                        </div>
                    </div>
                </div>
                <br /> <br /> <br /> <br />
                <Join />
                <br /> <br /> 

                <Industries />


            </div>
            <br /> <br />  <br /> <br />
            <Footer />
        </div>

    )
}


export default Banking

