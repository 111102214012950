import '../../css/industries.css';
import Nav from '../Nav';
import Footer from '../footer';
import Join from '../about/joinus';
import Platforms from '../marquee_platforms/platforms';
import Industries from '../about/industries';


import imgPath from '../../images/proveslogo(1).png'

function Manufacturing() {

    const height = {height:'16.8rem'}

    return (

        <div className='main-page'>

            <Nav bgColor='rgb(255, 215, 0);' fontcolor='black' imgPath={imgPath}/>
            <div className='about-main'>
                <div className='manufacturing-bg'>
                    <br /> <br /> <br /><br /> <br /> <br /> <br /><br />

                    <div className='container'>
                        <div className='row'>

                            <div className='col'>
                                <div className='solutions-box'>
                                    <h4 className='manufacture-header'>INDUSTRY / MANUFACTURING</h4>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='container'>
                    <Platforms />

                    <div className='row'>

                    </div>

                </div>


                <div className='services-bg'>
                    <div className='container'>
                        <div className='row'>

                            <center><h3 className='about-heading'>  <br />What We Do</h3>  <br /><br /></center>


                            <div className='col-lg-4 col-md-6'>
                                <div style={height}  className='cards-industries'>
                                    <h4 className='about-heading'>Predictive Maintenance</h4>
                                    <p className='industry-text'>Reduce downtime and maintenance costs with our predictive maintenance solutions. We use machine learning algorithms to predict equipment failures and schedule timely maintenance, ensuring smooth operations.</p>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6'>
                                <div style={height}  className='cards-industries'>
                                    <h4 className='about-heading'>Quality Control</h4>
                                    <p className='industry-text'>Enhance product quality and consistency with our quality control analytics. We help manufacturers monitor production processes, identify defects, and implement corrective actions to maintain high standards.</p>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6'>
                                <div style={height}  className='cards-industries'>
                                    <h4 className='about-heading'>Supply Chain Analytics</h4>
                                    <p className='industry-text'>Optimize your supply chain with our data-driven solutions. We provide insights into supplier performance, inventory levels, and demand forecasting to improve supply chain efficiency and reduce costs.</p>
                                </div>
                                <br /> <br />
                            </div>

                        </div>
                    </div>
                </div>
                <br /> <br /> <br /> <br />
                <Join />
                <br /> <br />

                <Industries />

            </div>
            <br /> <br />  <br /> <br />
            <Footer />
        </div>

    )
}


export default Manufacturing

