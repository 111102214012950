
import '../css/techplatforms.css';
import Platforms from './marquee_platforms/platforms';

function Techplatforms() {


    return(
        <div>
            <div className='container'>
            <center><h4 className="about-heading">TECHNOLOGY PLATFORMS</h4></center>
            <br/>    <br/>    <br/>    <br/>
                <div className='row'>
                   
           <Platforms/>
            </div>
        </div>
        <br/>    <br/>    <br/>    <br/>
        </div>
    )
}


export default Techplatforms

