import '../../../css/services/data&analytics.css';
import Nav from "../../Nav";
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudflare } from '@fortawesome/free-brands-svg-icons';
import { faChartLine, faBrain, faRobot, faDatabase, faChartBar, faChartPie, faLightbulb, faUserTie, faHandshake,faMicrochip } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';



function AnalyticsSolutions() {
  const styles = { color: 'red' }

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };


  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  
  const height = {
    height: isMobile ? '25rem' : '21rem', // Adjust height based on screen size
  };


  return (
    <div className='container-fluid solution-bg'>
      <br/>  <br/>
      <center><h4 className="about-heading">ANALYTICS SOLUTIONS</h4></center>
      <br /> <br /> 
     
      <section id="advertisers" class="advertisers-service-sec pt-5 pb-10">
        <div className="container">

          <div className="row ">
          <div className="col-lg-4 col-md-6">
            
              <div style={height} className="service-card">
                <div className="icon-wrapper">
                  <FontAwesomeIcon icon={faLightbulb} />
                </div>
                <h3 className='about-heading'>Data Analytics and Business Intelligence</h3>
                <p className='about-heading'>
                Our team of dedicated experts analyzes large and complex datasets to uncover valuable insights, trends, and actionable patterns.
                  <br/> <br/> <Link to="/data-analytics-bi-service"><p style={styles}><i class="bi bi-plus"></i>Learn more</p></Link>
                </p>
              </div>
            </div>


            <div className="col-lg-4 col-md-6 service-box">
              <div style={height}  className="service-card">
                <div className="icon-wrapper">
                  <FontAwesomeIcon icon={faDatabase} />
                </div>
                <h3 className='about-heading'>Big Data Analytics</h3>
                <p className='about-heading'>
                From scalable data engineering solutions to real-time analytics, we empower businesses to leverage large volumes of data for agile decision-making and operational insights.
                  <br/> <br/> <Link to="/bigdata-service"> <p style={styles}><i class="bi bi-plus"></i>Learn more</p></Link>
                </p>
              </div>
            </div>



            <div className="col-lg-4 col-md-6">
            <div style={height}  className="service-card">
                <div className="icon-wrapper">
                  <FontAwesomeIcon icon={faChartBar} />
                </div>
                <h3 className='about-heading'>Prescriptive Analytics</h3>
                <p className='about-heading'>
                Perspective Analytics as a Service offers real-time insights and predictive analytics to drive informed decision-making and enhance business performance.
                  <br/> <br/> <Link to="/prescriptive-analytics-service"> <p style={styles}><i class="bi bi-plus"></i>Learn more</p></Link>
                </p>
              </div>
            
            </div>


            <div className="col-lg-4 col-md-6">
            <div style={height}  className="service-card">
                <div className="icon-wrapper">
                  <FontAwesomeIcon icon={faChartPie} />
                </div>
                <h3 className='about-heading'>Predictive Analytics</h3>
                <p className='about-heading'>
                Enhance decision-making with advanced forecasting and predictive modeling services, powered by cutting-edge analytics.
                  <br/> <br/>  <Link to="/predictive-analytics-service"> <p style={styles}><i class="bi bi-plus"></i>Learn more</p></Link>
                </p>
              </div>
            </div>


            <div className="col-lg-4 col-md-6">
              <div  style={height} className="service-card">
                <div className="icon-wrapper">
                  <FontAwesomeIcon icon={faRobot} />
                </div>
                <h3 className='about-heading'>Machine Learning & AI Solutions</h3>
                <p className='about-heading'>
                  We develop predictive models, algorithms that forecast outcomes, improve decision-making, and automate repetitive tasks.
                  <br/> <br/> <Link to="/machine-learning-ai-service"><p style={styles}><i class="bi bi-plus"></i>Learn more</p></Link>
                </p>
              </div>
            </div>


            <div className="col-lg-4 col-md-6">
              <div style={height}  className="service-card">
                <div className="icon-wrapper">
                  <FontAwesomeIcon icon={faHandshake} />
                </div>
                <h3 className='about-heading'>Consulting and Strategy</h3>
                <p className='about-heading'>
                  We offer strategic guidance, customized solutions, and ongoing support to help organizations maximize their data assets.
                  <br/> <br/> <Link to="/consulting-service"> <p style={styles}><i class="bi bi-plus"></i>Learn more</p></Link>
                </p>
              </div>
            </div>



          </div>
        </div>
      </section>
      <br /> <br /> <br />
    </div>
  )
}

export default AnalyticsSolutions




