import '../../../../css/services/epm.css';
import Nav from '../../../Nav';
import Footer from '../../../footer';
import EPMLIST from '../epmserviceslist';
import Join from '../../../about/joinus';

// images
import cognos from '../../../../images/epmlist/epmservices/ibm/What-is-TM1(1).jpg'
import imgPath from '../../../../images/proveslogo(1).png'



function IBM() {

    return (
        <div className='main-page'>
            <Nav bgColor='rgb(255, 215, 0);' fontcolor='orange' imgPath={imgPath} />


            <div className='about-main'>
                <div className="ibm-image">
                    <br /> <br /> <br /><br /> <br /> <br /> <br /><br />

                    <div className='container'>
                        <div className='row'>

                            <div className='col'>
                                <div className='solutions-box'>
                                    <h4 className='contact-heading'>EPM / IBM</h4>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                <br /> <br /> <br /> <br /> <br />


                <div className='container'>
                    {/* First Row */}
                    <div className='row'>


                        <div className='col-1'></div>

                        <div data-aos="fade-in" data-aos-easing="ease-in-out-quad" data-aos-duration="2800" className='col-0_5'><h3 className='epm-number'>01</h3></div>

                        <div data-aos="fade-right" data-aos-easing="ease-in-out-quad" data-aos-duration="1800" className='col-md-4'>

                            <h4 className='about-heading'>Cognos TM1</h4>
                            <p className='about-company-paragraph'>We offer comprehensive services for IBM Planning Analytics (formerly Cognos TM1), helping you streamline financial planning,
                                budgeting, and forecasting. Our solutions enable you to create flexible and scalable models that support complex financial scenarios and drive better business outcomes.</p>

                        </div>

                        <div className='col-1'></div>


                        <div className='col-md-4'>

                            <div className="image-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="1800">
                                <img className="oracle-images" src={cognos} />
                            </div>
                        </div>

                    </div>

                    <br /><br />  <br /><br />

                </div>


                <br /><br />
                <EPMLIST />
                <br /><br />
                <Join />
                <br /><br /><br /><br />
            </div>
            <Footer />
        </div>
    )
}


export default IBM
