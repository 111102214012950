import logo from '../../images/about/dpmilogo.png';
import mission from '../../images/about/mission.png'
import vision from '../../images/about/vision.webp'


import '../../css/about.css';
import Nav from '../Nav';
import Footer from '../footer';
import OurTeam from './ourteam';
import Industries from './industries';
import Join from './joinus';
import imgPath from '../../images/provesyellow.png';
import WhyChoose from './whychoose';
import imgblack from '../../images/proveslogo(1).png';


function AboutUs() {
    return (
        <div className='main-page'>
            <Nav bgColor="#000000a8" imgPath={imgPath}/>
            <div className='about-main'>
                <div className='about-img-text'>
                <br /> <br /> <br /><br /> <br /> <br /> <br /><br />
                    <div className='container'>
                        <div className='row'>
                            <div className='col'>
                               
                                <h3 className='about-heading'>ABOUT PROVEZ</h3>
                            </div>
                            <div className='col'></div>
                        </div>
                    </div>
                </div>
                
                <div className='container '>
                    <br /><br /> <br /><br />
                    {/* First Row */}
                    <div className='row'>
                        <div className='col-12 col-md-5'>
                         
                            <br />
                            <p className='about-company-paragraph'>
                                At Provez, we are dedicated to unlocking the true potential of data through advanced analytics, machine learning,
                                and artificial intelligence. Our mission is to empower businesses with actionable insights and strategic intelligence, enabling them to make informed decisions and drive measurable outcomes.
                            </p>
                        </div>

                        <div className='col-12 col-md-2'></div>

                        <div className='col-12 col-md-5'>
                            <br />
                           
                            <img  className='symbol' src={imgblack} alt="Logo" />
                        
                        </div>
                    </div>

                    <br /><br /> <br /><br />
                    <h4 className='whatishead'></h4>
                    <br /><br /> <br /><br />  <br /><br />
                    {/* Second Row */}
                    <div className='row'>
                        <div className='col-12 col-md-5 '>
                            <div className='text-image'>
                                <img className='mission-vision' src={mission} alt="Mission Vision" />
                                <h2 className='about-company-header'>MISSION</h2>
                                <br /><br/>
                                <p className='about-company-paragraph'>
                                    Our mission is to provide cutting-edge analytics and AI solutions that help our clients navigate complex data landscapes, optimize their operations, and gain a competitive edge.
                                    We strive to deliver exceptional value through expertise, innovation, and a deep understanding of our client's needs.
                                </p>
                            </div>
                        </div>
                        <div className='col-12 col-md-2'></div>
                        <div className='col-12 col-md-5'>
                            <div className='text-image'>
                                <img className='mission-vision' src={vision} alt="Vision" />
                                <h2 className='about-company-header'>VISION</h2>
                                <br />
                                <p className='about-company-paragraph'>
                                    Our vision is to be a global leader in data-driven solutions, transforming businesses and industries through innovative and intelligent use of data. We aim to create a
                                    future where every organization can harness the power of data to achieve unparalleled success and growth.
                                </p>
                            </div>
                        </div>
                    </div>

                    <br /><br />  <br /><br /> <br /><br />
                    <h4 className='whatishead'></h4>

                    <br /><br /><br />
                    <WhyChoose/>
                  

                    <br /><br /> <br /><br />
                    <h4 className='whatishead'></h4>
                    <br /><br /> <br /><br />

                </div>
                <OurTeam />


                <br /><br /> <br /><br />
                <h4 className='whatishead'></h4>

                <Industries />
                <br /><br /> <br /><br />
                <Join />
                <br /><br /> <br /><br />
            </div>
            <Footer />
        </div>
    )
}

export default AboutUs;