
import '../../../css/services/epm.css';
import insights from '../../../images/epmlist/insights-removebg-preview.png'
import oracle from '../../../images/epmlist/oracle.png'
import sap from '../../../images/epmlist/sap.png'
import tangetik from '../../../images/epmlist/tangetik.webp'
import onestream from '../../../images/epmlist/onestream.webp'
import board from '../../../images/epmlist/board.webp'
import ibm from '../../../images/epmlist/ibm.png'
import anaplan from '../../../images/epmlist/anaplan.png'
import { Link } from 'react-router-dom';


function EPMLIST() {
    return (
        <div>

            <div className="container-fluid epm-service-image">
                <div  className="row text-center">
                    <h3 className="about-heading"><br />OUR EPM SERVICES</h3>
                    <p>Explore Our Comprehensive EPM Solutions</p>
                </div>

                <br /><br /><br />
                <div className='container'>
                <div className="row justify-content-center">
                    <div data-aos="fade-left" data-aos-easing="ease-in-out-quad" data-aos-duration="1800"  className="col-md-3 col-sm-6">
                        <div  className="epm-card text-center">
                            <img className="epm-images" src={oracle} alt="Oracle EPM Services" />
                            <h5 className="contact-heading">ORACLE EPM SERVICES</h5><br />
                            <Link to='/epm-oracle'> <button className='epm-view'>View</button></Link>
                        </div>
                    </div>

                    <div data-aos="fade-down" data-aos-easing="ease-in-out-quad" data-aos-duration="1800"  className="col-md-3 col-sm-6">
                        <div className="epm-card text-center">
                            <img className="epm-images" src={sap} alt="SAP EPM Solutions" />
                            <h5 className="contact-heading">SAP EPM SOLUTIONS</h5>
                            <br />
                            <Link to='/epm-sap'> <button className='epm-view'>View</button></Link>
                        </div>
                    </div>

                    <div data-aos="fade-down" data-aos-easing="ease-in-out-quad" data-aos-duration="1800"  className="col-md-3 col-sm-6">
                        <div className="epm-card text-center">
                            <img className="epm-images" src={anaplan} alt="Oracle EPM Services" />
                            <h5 className="contact-heading">ANAPLAN</h5>
                            <br />
                            <Link to='/epm-anaplan'>  <button className='epm-view'>View</button></Link>
                        </div>
                    </div>

                    <div data-aos="fade-right" data-aos-easing="ease-in-out-quad" data-aos-duration="1800"  className="col-md-3 col-sm-6">
                        <div className="epm-card text-center">
                            <img className="epm-images" src={ibm} alt="Oracle EPM Services" />
                            <h5 className="contact-heading">IBM PLANNING ANALYTICS</h5>
                            <br />
                            <Link to='/epm-ibm'>   <button className='epm-view'>View</button></Link>
                        </div>
                    </div>

                    <div data-aos="fade-right" data-aos-easing="ease-in-out-quad" data-aos-duration="1800"  className="col-md-3 col-sm-6">
                        <div className="epm-card text-center">
                            <img className="epm-images" src={onestream} alt="Oracle EPM Services" />
                            <h5 className="contact-heading">ONESTREAM XF</h5>
                            <br />
                            <Link to='/epm-onestream'>      <button className='epm-view'>View</button></Link>
                        </div>
                    </div>

                    <div data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="1800"  className="col-md-3 col-sm-6">
                        <div  className="epm-card text-center">
                            <img className="epm-images" src={insights} alt="Oracle EPM Services" />
                            <h5 className="contact-heading">ADAPTIVE INSIGHTS</h5>
                            <br />
                            <Link to='/epm-insights'> <button className='epm-view'>View</button></Link>
                        </div>
                    </div>

                    <div data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="1500"  className="col-md-3 col-sm-6">
                        <div className="epm-card text-center">
                            <img className="epm-images" src={tangetik} alt="Oracle EPM Services" />
                            <h5 className="contact-heading">TANGETIK</h5>
                            <br />
                            <Link to='/epm-tangetik'>  <button className='epm-view'>View</button> </Link>
                        </div>
                    </div>

                    <div data-aos="fade-left" data-aos-easing="ease-in-out-quad" data-aos-duration="1500"  className="col-md-3 col-sm-6">
                        <div className="epm-card text-center">
                            <img className="epm-images" src={board} alt="Oracle EPM Services" />
                            <h5 className="contact-heading">BOARD INTERNATIONAL</h5>
                            <br />
                            <Link to='/epm-board'>      <button className='epm-view'>View</button></Link>
                        </div>
                    </div>

                </div>
                </div>
            </div>
        </div>
    )
}



export default EPMLIST