import '../../../../css/services/epm.css';




function WhyOracle() {
    return (
        <div className="container mt-5 whyoracle">

            <br/><br/>
            <center><h3 className='about-heading'>WHY ORACLE EPM ?</h3></center>
            <br/><br/>
            <div className="row">
                {/* Card 1 */}
                <div className="col-md-6 col-lg-4 mb-4">
                    <div className="card h-100">
                        <div className="card-body">
                            <h5 className="card-title">Expertise</h5>
                            <p className="card-text">
                                Our team consists of certified Oracle EPM professionals with extensive experience in implementing and optimizing Oracle EPM solutions. We bring deep technical knowledge and industry insights to every project.
                            </p>
                        </div>
                    </div>
                </div>
                {/* Card 2 */}
                <div className="col-md-6 col-lg-4 mb-4">
                    <div className="card h-100">
                        <div className="card-body">
                            <h5 className="card-title">Customization</h5>
                            <p className="card-text">
                                We understand that every business is unique. Our services are tailored to meet your specific needs, ensuring that the Oracle EPM solution aligns with your strategic objectives and operational requirements.
                            </p>
                        </div>
                    </div>
                </div>
                {/* Card 3 */}
                <div className="col-md-6 col-lg-4 mb-4">
                    <div className="card h-100">
                        <div className="card-body">
                            <h5 className="card-title">End-to-End Support</h5>
                            <p className="card-text">
                                From initial consultation and implementation to ongoing support and optimization, we provide comprehensive services throughout the entire EPM lifecycle. Our goal is to be your trusted partner in achieving financial excellence.
                            </p>
                        </div>
                    </div>
                </div>
                {/* Card 4 */}
                <div className="col-md-6 col-lg-4 mb-4">
                    <div className="card h-100">
                        <div className="card-body">
                            <h5 className="card-title">Proven Methodology</h5>
                            <p className="card-text">
                                Our implementation methodology is based on best practices and a structured approach that minimizes risks and ensures successful project delivery. We focus on delivering value quickly and efficiently.
                            </p>
                        </div>
                    </div>
                </div>
                {/* Card 5 */}
                <div className="col-md-6 col-lg-4 mb-4">
                    <div className="card h-100">
                        <div className="card-body">
                            <h5 className="card-title">Customer-Centric Approach</h5>
                            <p className="card-text">
                                We prioritize your satisfaction and success. Our team works closely with you to understand your challenges, provide personalized solutions, and ensure that you achieve your desired outcomes.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <br/><br/>
        </div>
    );
}

export default WhyOracle
