import '../css/App.css';
import Nav from "./Nav";
import React, { useState } from 'react';
import Services from './services';
import AnalyticsSolutions from './services/DataAnalytics/data&analyticslist';
import Techplatforms from './techplatforms';
import Footer from './footer';
import AboutUs from './about/about';
import imgPath from '../images/provesyellow.png';
import Logo from '../images/provezsymbol.png'
import ExploreGenAI from './exploregenai';
import { Link } from 'react-router-dom';
import AiAssistant from './AiAssistant';


function Landing() {

    const width = {width:'15rem'}


    return (
        <div className='main-page'>

            <Nav bgColor="#000000a8" imgPath={imgPath}/>
        
            <div className='landing-image'>

                {/* <div className={`landing-image`}> */}
                <div className="container">

                    <div className="row">

                        <div className="col-md-7">

                        </div>
                        
                        <div className='col-md-5' >

                        <br/><br/> <br/><br/>
                            <div className='box-intro'>
                             <h1 className="animated-text">Harnessing Innovation...</h1>
                            <h1 className="animated-text">Transforming Data...</h1>
                            <h1 className="animated-text">Empowering Success...</h1>

                            <img className="animated-text-welcome" loading="lazy" style={width}  src={Logo} /> <br/> <br/>
                         <Link to='/contact'>   <button className="connect"> Let's Connect</button></Link>
                            </div>

                        </div>

                    </div>

                    <div className='row'>
                        <div className='col-9'></div>
                        <div className='col'>


                        </div>

                    </div>


                </div>

                <br />   <br /> <br />   <br /> <br />   <br /> <br />   <br /> <br />   <br /> <br />    <br /> <br />    <br /> <br />    <br /> <br />    <br /> <br />
            </div>
            <ExploreGenAI/>
            <br />    <br />
            <Services />
            {/* <What /> */}
            <AnalyticsSolutions />  <br/>  <br/>
            <Techplatforms />
            <AiAssistant/>
            <Footer />

        </div>
    )
}

export default Landing




