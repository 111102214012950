
import React, { useState, useEffect } from 'react';
import '../../css/genai.css'
import Nav from '../Nav';
import imgPath from '../../images/provesyellow.png'
import KeyServicesAI from './keyservices';
import Footer from '../footer';
import KeyPlayers from './keyplayers';
import Join from '../about/joinus';
import Typewriter from './type'
import { Link } from 'react-router-dom';

//  Images
import logo from '../../images/genai/symbols/genai-logo.jpeg'
import robot from '../../images/genai/symbols/robot.jpg'
import star from '../../images/genai/symbols/star.png'
import MarqueeTools from './marqueetools';
import ai_robot from '../../images/genai/whatisai-robot.png'

function GenAI() {

    return (
        <div className='main-page'>
            <Nav bgColor="#08087c" fontcolor='yellow' imgPath={imgPath} />
            <div className='about-main'>
                <div className='gen-ai-image'>
                    <br /> <br /> <br /><br /> <br /> <br /> <br /><br /><br />
                    <div className='container'>
                        <div className='row'>
                            <div className='col'>

                                <h3 className='contact-heading'>GENERATIVE AI</h3>

                            </div>
                            <div className='col'></div>
                        </div>
                    </div>
                </div>


                <div className='container-fluid ai-container-bg'>
             {/* <center>  <img className='ai-side-images' src={logo}/>  </center>   */}
             


                    {/* Row */}
                    <div className='row ai-text-bg '>
                        
                        {/* <div className='col-1'></div> */}

                        <div className='col-1'></div>
                      
                        
                        <div className='col-md-5 '>   <br/>  <br/>  <br/> <h4 className='about-heading'>What is Gen AI ?</h4>
                             <p className='about-company-paragraph'>
                            

                            Generative AI refers to artificial intelligence systems capable of creating content such as text, images, music, and more, mimicking human creativity and intelligence. These advanced AI models, like GPT (Generative Pre-trained Transformer) for text and GANs
                            (Generative Adversarial Networks) for images, can generate highly realistic and contextually relevant content.
                            <br /><br />
                            Gen AI services provide comprehensive solutions that span the entire lifecycle of AI implementation, from initial concept and strategy to deployment and ongoing support. These services leverage advanced AI models to create content, optimize processes, and enhance decision-making. Here is an outline of the end-to-end generative AI services:<br /><br />
                           <Link to='/gen-ai-endtoendservice'> <button className='read-more'>Read More</button></Link>
                        </p></div>
                  
                       
                        
                        <div className='col'> <br/>  <br/>  <br/>  <br/>  <br/>  <br/> <img  className='whatisgen-image' src={ai_robot}/> </div>

                    </div>

                 

                </div>
                <MarqueeTools/>
                <KeyServicesAI />

               
                <KeyPlayers />


                <br /><br /><br />   <br /><br /><br />
                <Join />
                <br /><br /><br /><br />



            </div>
            <Footer />
        </div>
    )
}


export default GenAI

