// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import routes from './routes';
import ScrollToTop from './hook/ScrollToTop';



function TitleManager() {
  const location = useLocation();
  const currentRoute = routes.find(route => route.path === location.pathname);
  const title = currentRoute ? currentRoute.title : 'Provez';

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
}


function App() {
  
  return (
    <div>
      <Router>
        <ScrollToTop />
        <TitleManager />
        <Routes>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} element={<route.component />} />
          ))}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
