import '../../css/contact.css';
import Nav from '../Nav';
import Footer from '../footer';
import imgPath from '../../images/provesyellow.png';
import contactform from '../../images/contact-form.jpg';


import React, { useState } from 'react';

import OurAddress from './ouraddress';

function Contact() {

    const color = { color: 'red' };

    const [submitting, setSubmitting] = useState(false); // State to manage form submission status
    const [isDisabled, setIsDisabled] = useState(false);

    const onSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        // Handle form data as needed, for example, appending additional data
        formData.append("access_key", "7cc6bcac-94e8-4701-9f74-f3c75dc2b289");
        formData.append("from_name", "Enquiry Provez");
        // formData.append("from_email", "notify@web3forms.com");

        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);

        try {
            setSubmitting(true); // Set submitting state to true

            // Example API call, replace with your actual API endpoint
            const res = await fetch("https://api.web3forms.com/submit", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json"
                },
                body: json
            }).then((res) => res.json());

            if (res.success) {
                console.log("Success", res);
                alert("Form submitted successfully!");
                event.target.reset(); // Reset form after successful submission
                setIsDisabled(true); // Disable the button
                setTimeout(() => {
                    setIsDisabled(false); // Re-enable the button after 10-15 seconds
                }, 15000);
            } else {
                console.error("Error", res);
                alert("Failed to submit form. Please try again later.");
            }
        } catch (error) {
            console.error("Error", error);
            alert("An error occurred while submitting the form.");
        } finally {
            setSubmitting(false); // Reset submitting state
        }
    };

    return (
        <div className='main-page contact-color'>
            <Nav bgColor='#000000a8' imgPath={imgPath} />
            <div className='about-main'>
                <div className='contact-img-text'>
                    <br /> <br /> <br /><br /> <br /> <br /> <br />
                    <div className='container'>
                        <div className='row'>
                            <div className='col'>
                                <h3 className='contact-heading'>CONTACT</h3>
                            </div>
                            <div className='col'></div>
                        </div>
                    </div>
                </div>

                <br /> <br /> 
                <br /> <br /> 

                <div className='container '>
                    <div className='row'>

                        <div className='col-md-5'>
                            <section id="contact" data-aos="zoom-in" data-aos-easing="ease-in-out-quad" data-aos-duration="1000" data-aos-delay="1000">
                                <div className="containers">
                                    <div class="conversation-container">
                                        <div className="conversation-bubble about-heading">
                                            <h1>Let's Work Together</h1><br/>
                                            <p>"Have an Idea or a Challenge? We’re Here to Help. Fill Out the Form and Let’s Start the Conversation!"</p>
                                        </div>
                                     
                                    </div><br/>
                                    <img className='contact-special-image' src={contactform} />
                                </div>
                            </section><br/>

                        </div>


                        <div className='col-md-6 contact-form' >
                            <form onSubmit={onSubmit}>
                                <label className='address'>Full Name <span style={color}>*</span></label><br />
                                <input name="fullname" type='text' required />

                                <label className='address'>Email ID <span style={color}>*</span></label>
                                <input name="email" type='text' required />

                                <label className='address'>Mobile Number</label>
                                <input name="mobile" type='text' />

                                <label className='address'>Company</label>
                                <input name="company" type='text' />

                                <label className='address'>Message <span style={color}>*</span></label>
                                <input name="message" type='text' required />
                                <br />

                                <input
                                    className='btn btn-dark'
                                    type='submit'
                                    value={submitting ? 'Submitting...' : 'Submit'}
                                    disabled={isDisabled || submitting}
                                />  <br />  <br />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <br /><br /><br />
            <OurAddress />
            <br /><br /><br />
            <Footer />
        </div>
    );
}

export default Contact;