import '../../../../css/solutions.css';
import Nav from '../../../Nav';
import Footer from '../../../footer';
import AnalyticsSolutions from '../data&analyticslist';
import Platforms from '../../../marquee_platforms/platforms';
import Join from '../../../about/joinus';


import imgPath from '../../../../images/provesyellow.png'

function MachineLearning_AI() {
    const height = {height:'17rem'}

    return (


        <div className='main-page'>

            <Nav bgColor='rgb(255, 215, 0);' fontcolor='yellow' imgPath={imgPath} />
            <div className='about-main'>
                <div className='machine-learning-bg'>
                    <br /> <br /> <br /><br /> <br /> <br /> <br /><br /><br />

                    <div className='container'>
                        <div className='row'>

                            <div className='col'>
                                <div className='solutions-box'>
                                    <h4>SOLUTIONS / AI/ML </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='container'>
                    <Platforms />

                    <div className='row'>

                        <div className='col-md-6'>
                            <h2 className='about-heading'>Empowering Businesses with AI-ML Excellence and 30% Savings!</h2>
                            <br />
                        </div>

                        <div className='col'>
                            <p className='about-company-paragraph'>
                                AI and ML technologies play a pivotal role as businesses adapt to uncertainties, yet many falter due to a lack of expertise, scalability issues, and a struggle to identify suitable use cases.
                               
                            </p>

                        </div>

                    </div>

                </div>


                <div className='services-bg'>
                    <div className='container'>
                        <div className='row'>

                            <center><h3 className='about-heading'>  <br /><br /><br />AI/ML Services</h3>  <br /><br /></center>

                            <div className='col-lg-4 col-md-6'>
                                <div style={height}  className='cards-industries'>
                                    <h4 className='about-heading'>Machine Learning Models</h4>
                                    <p className='about-company-paragraph'>Develop and deploy machine learning models tailored to your specific needs. Our experts design algorithms that can learn from data and improve over time, enabling you to automate tasks and gain deeper insights.</p>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6'>
                                <div style={height}  className='cards-industries'>
                                    <h4 className='about-heading'>Natural Language Processing (NLP)</h4>
                                    <p className='about-company-paragraph'>Leverage the power of NLP to analyze and understand textual data. Our NLP solutions help you extract valuable information from unstructured data sources such as social media, customer reviews, and support tickets.</p>
                                </div>
                            </div>


                            <div className='col-lg-4 col-md-6'>
                                <div style={height}  className='cards-industries'>
                                    <h4 className='about-heading'>Computer Vision </h4>
                                    <p className='about-company-paragraph'>

                                    Unlock the potential of visual data with our computer vision solutions. We build and implement models that can analyze images and videos, enabling applications such as image recognition, object detection, and video analytics.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <br />   <br />
                </div>
                <br /> <br /> <br /> <br />
                <Join />
                <br /> <br /> <br /> <br /> <br />

                <AnalyticsSolutions />

            </div>
            <Footer />
        </div>



    )


}

export default MachineLearning_AI