


// Images
import openai from '../../images/genai/key-players/openai.png'
import azure from '../../images/genai/key-players/azure.webp'
import google from '../../images/genai/key-players/google-ai.png'
import ibm from '../../images/genai/key-players/ibm-watson.png'
import adobe from '../../images/genai/key-players/adobe.png'


function MarqueeKeyPlayers()   {


    return(
        <div>
        <marquee><img className="keyplayers" src={openai} />&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
            <img className="keyplayers" src={adobe}/>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
            <img className="keyplayers" src={azure} /> &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
            <img className="keyplayers" src={ibm}  /> &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
            <img className="keyplayers" src={google} /> &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
         
        </marquee>
       
       
    </div>
    )
}


export default MarqueeKeyPlayers