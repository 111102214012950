import React, { useState, useRef, useEffect } from 'react';
import '../css/App.css';
import { Link } from 'react-router-dom';

function Nav({ bgColor, imgPath, fontcolor }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    // Close dropdown when user clicks outside
    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    // Add event listener to handle clicks outside dropdown
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };



    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark fixed-top p-4" style={{ backgroundColor: bgColor }}>
                <div className="container">
                    <div className="d-flex">
                        <div>
                            <Link to="/">
                                <img className="logo" loading="lazy" src={imgPath} alt="description" width='240px' />
                            </Link>
                        </div>
                    </div>

                    <button className="navbar-toggler custom-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link style={{ color: fontcolor }} className="nav-link glowing" to="/gen-ai">GEN&nbsp;AI&nbsp;✨ </Link>
                            </li>

                            <li ref={dropdownRef} className={`nav-item dropdown ${dropdownOpen ? 'show' : ''}`}>
                                <a
                                    style={{ color: fontcolor }}
                                    className="nav-link dropdown-toggle"
                                    href="#"
                                    id="navbarDropdown"
                                    role="button"
                                    onClick={toggleDropdown}
                                >
                                    SERVICES
                                </a>

                                <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} aria-labelledby="navbarDropdown">
                                    <Link className="dropdown-item" to="/epm-services">ENTERPRISE PERFORMANCE MANAGEMENT</Link>
                                    <Link className="dropdown-item" to="/data-analytics">DATA & ANALYTICS</Link>
                                    <Link className="dropdown-item" to='/digital-transformation-service'>DIGITAL TRANSFORMATION</Link>
                                    <Link className="dropdown-item" to='/staffing-augmentation-service'>STAFFING AUGMENTATION SERVICES</Link>
                                </div>
                            </li>

                            <li className="nav-item">
                                <Link style={{ color: fontcolor }} className="nav-link except" to="/about">ABOUT</Link>
                            </li>

                            {/* <li className="nav-item">
                                <a href="/careers" style={{ color: fontcolor }} className="nav-link except">CAREERS</a>
                            </li> */}


                            <li className="nav-item">
                                <Link style={{ color: fontcolor }} className="nav-link except" to="/contact">CONTACT</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Nav;