import '../../../../css/services/epm.css';
import Nav from '../../../Nav';
import Footer from '../../../footer';
import EPMLIST from '../epmserviceslist';
import Join from '../../../about/joinus';



// images
import planning from '../../../../images/epmlist/epmservices/anaplan/planninganalpan.png'
import performance from '../../../../images/epmlist/epmservices/anaplan/performance.jpg'
import imgPath from '../../../../images/proveslogo(1).png'

function Anaplan() {


    return (
        <div className='main-page'>
            <Nav bgColor='rgb(255, 215, 0);' fontcolor='black' imgPath={imgPath} />


            <div className='about-main'>
                <div className="anaplan-image">
                    <br /> <br /> <br /><br /> <br /> <br /> <br /><br />

                    <div className='container'>
                        <div className='row'>

                            <div className='col'>
                                <div className='solutions-box'>
                                    <h4 className='about-heading'>EPM / ANAPLAN</h4>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>



                <br /> <br /> <br /> <br /> <br />


                <div className='container'>
                    {/* First Row */}
                    <div className='row'>


                        <div className='col-1'></div>

                        <div data-aos="fade-in" data-aos-easing="ease-in-out-quad" data-aos-duration="2800" className='col-0_5'><h3 className='epm-number'>01</h3></div>

                        <div data-aos="fade-right" data-aos-easing="ease-in-out-quad" data-aos-duration="1800" className='col-md-4'>

                            <h4 className='about-heading'>Sales Performance Management</h4>
                            <p className='about-company-paragraph'>Optimize your sales planning and performance management with Anaplan. We provide solutions to enhance sales forecasting, territory and quota management,
                                and incentive compensation, ensuring your sales teams are aligned and motivated.</p>

                        </div>

                        <div className='col-1'></div>


                        <div className='col-md-4'>

                            <div className="image-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="1800">
                                <img className="oracle-images" src={performance}/>
                            </div>
                        </div>


                    </div>

                    <br /><br />  <br /><br />


                    {/* Second Row */}
                    <div className='row'>


                        <div className='col-1'></div>

                        <div data-aos="fade-in" data-aos-easing="ease-in-out-quad" data-aos-duration="2800" className='col-0_5'><h3 className='epm-number'>02</h3></div>

                        <div data-aos="fade-right" data-aos-easing="ease-in-out-quad" data-aos-duration="1800" className='col-md-4'>

                            <h4 className='about-heading'>Connected Planning</h4>
                            <p className='about-company-paragraph'>Anaplan's connected planning platform enables collaborative and dynamic planning across your organization.
                                Our services include implementation, customization, and support to help you build and maintain effective planning models that align with your business objectives.</p>

                        </div>

                        <div className='col-1'></div>


                        <div className='col-md-4'>

                            <div className="image-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="1800">
                                <img className="oracle-images" src={planning} />
                            </div>
                        </div>


                    </div>




                </div>


                <br /><br />
                <EPMLIST />
                <br /><br />
                <Join />
                <br /><br /><br /><br />
            </div>
            <Footer />
        </div>
    )
}


export default Anaplan