import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const useAOS = () => {
    useEffect(() => {
      AOS.init({
        duration: 500,
        easing: 'ease-in-out-quad',
        once: false,
      });
  
      window.addEventListener('load', AOS.refresh);
  
      return () => {
        window.removeEventListener('load', AOS.refresh);
      };
    }, []);
  
    return (
      <div>
    
      </div>
    );
  }
  

export default useAOS;

