import '../../../css/services/digitaltransform.css';
import Nav from "../../Nav"
import Footer from "../../footer"
import Platforms from '../../marquee_platforms/platforms';
import Join from "../../about/joinus";
import imgPath from '../../../images/provesyellow.png';

import Approach from './approach';
import WhyDgTransform from './whychoosedgtransform';
import GetStartToday from './getstratedtoday';


function DigitalTransformation() {

   

    return (
        <div className='main-page'>
            <Nav bgColor='#000000a8' imgPath={imgPath} />
            <div className='about-main'>
                <div className="dgtransform-image">
                    <br /><br /><br /><br /><br /><br /> <br /><br />
                    <div className='container'>
                        <div className='row'>
                            <div className='col'>

                                <h4 className='contact-heading'>DIGITAL TRANSFORMATION</h4>
                            </div>
                            <div className='col'></div>
                        </div>
                    </div>

                </div>
                <div className='container'>
                    <Platforms />

                    <div className='row'>

                        <div className='col-md-6'>
                            <h2 className='about-heading'>Revolutionizing business with technology-driven innovation and efficiency for a smarter, more agile future.</h2>
                            <br />
                        </div>

                        <div className='col'>
                            <p className='industries-readmore'>At Provez, we specialize in driving digital transformation for organizations across various industries. Our expertise lies in
                                leveraging cutting-edge technologies and advanced analytics to help businesses evolve in the digital age. We empower our clients to achieve
                                operational excellence, enhance customer experiences, and create new value propositions through comprehensive digital strategies.
                                <br />  <br />

                            </p>



                        </div>

                    </div>

                    <br /><br /><br /><br />

                    <h4 className='whatishead'></h4>
                    <br /><br /><br /><br />

                </div>

                <Approach />



                <br /><br />  <br /><br />
                <WhyDgTransform />
                <br /><br />  <br /><br />
                <GetStartToday />
                <br /><br />  <br /><br />


            </div>


            <Footer />

        </div>
    )
}




export default DigitalTransformation

