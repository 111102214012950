
import anomaly from '../../images/genai/key-services/anomaly-detection.webp'
import chatbots from '../../images/genai/key-services/chatbots.jpg'
import customize from '../../images/genai/key-services/customized-learning.jpg'
import graphics from '../../images/genai/key-services/graphic-design.jpeg'
import imggenerate from  '../../images/genai/key-services/image-generaion.webp'
import music from '../../images/genai/key-services/music-composition.jpg'
import marketing from '../../images/genai/key-services/personalised-marketing.png'
import modeling from '../../images/genai/key-services/predictive-modeling.webp'
import sythetic from '../../images/genai/key-services/synthetic-datax1000.jpg'
import text from '../../images/genai/key-services/text-generation.jpg'
import summary from '../../images/genai/key-services/textsummary.png'
import translate from '../../images/genai/key-services/translation.jpg'
import video from '../../images/genai/key-services/video-generation.gif'
import venv from '../../images/genai/key-services/virtual-env.webp'



function KeyServicesAI() {

    return (

        <div className="container-fluid key-services-bg">
            <div className='container'>
            <br/>  <br/>  <br/>
            <div className="same-line-img"><h3 className='about-heading'>Key services offered under Generative AI &nbsp;✨ </h3></div>
            <br /> <br />

            {/* First Row */}
            <div className="row key-service-row">

                <center><h4 className="about-heading">1. Content Creation</h4></center>


                {/* first col */}
                <div className="col-lg-1 col-md-0"></div>
                <div className="col-lg-3 col-md-5"><br />
                    <div data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="500" className="box-vertical">
                        <img className="service-images" src={text} />
                        <br /><br />
                        <strong className="about-heading">Text Generation</strong><br />
                        <p className="about-company-paragraph"> AI systems like GPT-4 can generate articles, reports, summaries, and even creative writing such as poetry and fiction.</p>
                    </div>
                </div>


                {/* second col */}

                <div className="col-lg-3 col-md-5"><br />
                    <div data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="500" data-aos-delay="300" className="box-vertical">
                        <img className="service-images" src={imggenerate} />
                        <br /><br />
                        <strong className="about-heading">Image Generation</strong><br />
                        <p className="about-company-paragraph"> Tools like DALL-E and MidJourney create images from textual descriptions, useful for marketing, design, and entertainment.</p>
                    </div>
                </div>

                {/* 3rd col */}

                <div className="col-lg-3 col-md-5"><br />
                    <div data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="500" data-aos-delay="500" className="box-vertical">
                        <img className="service-images" src={video} />
                        <br /><br />
                        <strong className="about-heading">Video Generation</strong><br />
                        <p className="about-company-paragraph"> AI can create videos based on scripts or generate synthetic media for training, marketing, and entertainment.</p>
                    </div>
                </div>

                <br />
            </div>

            <br /><br /><br /><br /><br /> <br /><br />

            {/* Second Row */}
            <div className="row">

                <center> <h4 className="about-heading">2. Natural Language Processing (NLP) </h4></center>


                {/* first col */}
                <div className="col-lg-1 col-md-0"></div>
                <div className="col-lg-3 col-md-5"><br />
                    <div data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="500" className="box-vertical">
                        <img className="service-images" src={chatbots} />
                        <br /><br />
                        <strong className="about-heading">Chatbots and Virtual Assistants</strong><br />
                        <p className="about-company-paragraph">AI-driven chatbots can handle customer service, provide information, and assist with tasks, enhancing user experience.</p>
                    </div>
                </div>


                {/* second col */}

                <div className="col-lg-3 col-md-5"><br />
                    <div data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="500" data-aos-delay="300" className="box-vertical">
                        <img className="service-images" src={translate} />
                        <br /><br />
                        <strong className="about-heading">Language Translation</strong><br />
                        <p className="about-company-paragraph"> Generative AI can translate text and speech in real-time, breaking down language barriers in global communication.</p>
                    </div>
                </div>

                {/* 3rd col */}

                <div className="col-lg-3 col-md-5"><br />
                    <div data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="500" data-aos-delay="500" className="box-vertical">
                        <img className="service-images" src={summary} />
                        <br /><br />
                        <strong className="about-heading">Text Summarization</strong><br />
                        <p className="about-company-paragraph"> AI can summarize long documents, articles, and reports, saving time and improving information accessibility.</p>
                    </div>
                </div>

                <br />
            </div>




            <br /><br /><br /><br /><br /> <br /><br />

            {/* Third Row */}
            <div className="row">

                <center>  <h4 className="about-heading">3. Personalization and Recommendations</h4></center>


                {/* first col */}
                <div className="col-lg-1 col-md-0"></div>
                <div className="col-lg-3 col-md-5"><br />
                    <div data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="500" className="box-vertical">
                        <img className="service-images" src={marketing} />
                        <br /><br />
                        <strong className="about-heading">Personalized Marketing</strong><br />
                        <p className="about-company-paragraph">AI generates personalized content and product recommendations based on user behavior and preferences.</p>
                    </div>
                </div>

                {/* second col */}

                <div className="col-lg-3 col-md-5"><br />
                    <div data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="500" data-aos-delay="300" className="box-vertical">
                        <img className="service-images" src={customize} />
                        <br /><br />
                        <strong className="about-heading">Customized Learning</strong><br />
                        <p className="about-company-paragraph">AI creates personalized educational content, adapting to individual learning styles and needs.</p>
                    </div>
                </div>

                <br />
            </div>




            <br /><br /><br /><br /><br /> <br /><br />   

            {/* 4th Row */}
            <div className="row">

                <center><h4 className="about-heading">4. Design and Creativity</h4></center>


                {/* first col */}
                <div className="col-lg-1 col-md-0"></div>
                <div className="col-lg-3 col-md-5"><br />
                    <div data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="500" className="box-vertical">
                        <img className="service-images" src={graphics} />
                        <br /><br />
                        <strong className="about-heading">Graphic Design</strong><br />
                        <p className="about-company-paragraph">AI tools assist in creating logos, banners, and other design elements, streamlining the design process.</p>
                    </div>
                </div>

                {/* second col */}

                <div className="col-lg-3 col-md-5"><br />
                    <div data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="500" data-aos-delay="300" className="box-vertical">
                        <img className="service-images" src={music} />
                        <br /><br />
                        <strong className="about-heading">Music Composition</strong><br />
                        <p className="about-company-paragraph">AI systems can compose original music, providing unique soundtracks for various applications.</p>
                    </div>
                </div>

                <br />
            </div>


            <br /><br /><br /><br /><br /> <br /><br />

            {/* 5th Row */}
            <div className="row">

                <center>   <h4 className="about-heading">5. Data Augmentation and Synthesis</h4></center>


                {/* first col */}
                <div className="col-lg-1 col-md-0"></div>
                <div className="col-lg-3 col-md-5"><br />
                    <div data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="500" className="box-vertical">
                        <img className="service-images" src={sythetic} />
                        <br /><br />
                        <strong className="about-heading">Synthetic Data Generation</strong><br />
                        <p className="about-company-paragraph"> AI creates synthetic data for training machine learning models, especially useful in scenarios with limited real-world data.</p>
                    </div>
                </div>

                {/* second col */}

                <div className="col-lg-3 col-md-5"><br />
                    <div data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="500" data-aos-delay="300" className="box-vertical">
                        <img className="service-images" src={anomaly}/>
                        <br /><br />
                        <strong className="about-heading">Anomaly Detection</strong><br />
                        <p className="about-company-paragraph">Generative AI helps in identifying anomalies by comparing generated normal data patterns with real data.</p>
                    </div>
                </div>

                <br />
            </div>



            <br /><br /><br /><br /><br /> <br /><br />   

            {/* 6th Row */}
            <div className="row">

                <center>    <h4 className="about-heading">6. Simulation and Modeling</h4></center>


                {/* first col */}
                <div className="col-lg-1 col-md-0"></div>
                <div className="col-lg-3 col-md-5"><br />
                    <div data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="500" className="box-vertical">
                        <img className="service-images" src={venv} />
                        <br /><br />
                        <strong className="about-heading">Virtual Environments</strong><br />
                        <p className="about-company-paragraph"> AI generates realistic virtual environments for training, gaming, and simulations.</p>
                    </div>
                </div>

                {/* second col */}

                <div className="col-lg-3 col-md-5"><br />
                    <div data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="500" data-aos-delay="300" className="box-vertical">
                        <img className="service-images" src={modeling} />
                        <br /><br />
                        <strong className="about-heading">Predictive Modeling</strong><br />
                        <p className="about-company-paragraph">AI generates scenarios and simulations to predict outcomes in fields such as finance, healthcare, and logistics.</p>
                    </div>
                </div>

                <br />  
            </div>


            <br/>  <br/><br/>  <br/><br/>  <br/><br/>  <br/><br/>  <br/>
            </div>
        </div>
    )
}


export default KeyServicesAI