import '../../css/industries.css';
import Nav from '../Nav';
import Footer from '../footer';
import Join from '../about/joinus';
import Platforms from '../marquee_platforms/platforms';
import Industries from '../about/industries';


import imgPath from '../../images/proveslogo(1).png'

function Retail()  {

    const width = {fontSize:'23px'}
    const height ={height:'18rem'}

    return(
        <div className='main-page'>

        <Nav bgColor='rgb(255, 215, 0);' fontcolor='black' imgPath={imgPath}/>
        <div className='about-main'>
            <div className='retail-bg'>
                <br /> <br /> <br /><br /> <br /> <br /> <br /><br />

                <div className='container'>
                    <div className='row'>

                        <div className='col'>
                            <div className='solutions-box'>
                                <h4 className='finance-header'>INDUSTRY / RETAIL & ECOMMERCE</h4>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className='container'>
                <Platforms />

                <div className='row'>

                </div>

            </div>


            <div className='services-bg'>
                <div className='container'>
                    <div className='row'>

                        <center><h3 className='about-heading'>  <br />What We Do</h3>  <br /><br /></center>

                        <div className='col-lg-4 col-md-6'>
                            <div style={height}  className='cards-industries'>
                                <h4 className='about-heading'>Customer Analytics</h4>
                                <p className='industry-text'>Understand and anticipate customer needs with our retail analytics services. We analyze purchasing patterns, customer preferences, and market trends to develop personalized marketing campaigns and optimize product assortments.</p>
                            </div>
                        </div>

                      
                        <div className='col-lg-4 col-md-6'>
                            <div style={height}  className='cards-industries'>
                                <h4 style={width} className='about-heading'>Supply Chain Optimization</h4>
                                <p  className='industry-text'>Streamline your supply chain operations with our advanced analytics solutions. We provide insights into inventory management, demand forecasting, and logistics optimization to reduce costs and improve efficiency.</p>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6'>
                            <div style={height}  className='cards-industries'>
                                <h4  className='about-heading'>Sales and Performance Analytics</h4>
                                <p  className='industry-text'>Boost sales and profitability with our performance analytics services. We help retailers analyze sales data, identify growth opportunities, and measure the effectiveness of marketing strategies.</p>
                            </div>
                            <br /><br />
                        </div>

                    </div>
                </div>
            </div>
            <br /> <br /> <br /> <br />
            <Join />
            <br /> <br /> 

            <Industries />


        </div>
        <br /> <br />  <br /> <br />
        <Footer />
    </div>
    )

}


export default Retail
