import Landing from './project/Landing';
import AboutUs from "./project/about/about";
import Contact from "./project/contact/contact";
// Industries
import Banking from "./project/industries/Finance&banking";
import HealthCare from "./project/industries/healthcare";
import Retail from "./project/industries/retail&ecommerce";
import Manufacturing from "./project/industries/manufacturing";
import Energy from "./project/industries/energy";
import Telecommunications from "./project/industries/telecommunications";
// Services EPM
import Sector from "./project/industries/go&public";
import EPM from "./project/services/EPM/epm";
import Oracle from "./project/services/EPM/oracle/oracle";
import SAP from "./project/services/EPM/sap/sap";
import Anaplan from "./project/services/EPM/anaplan/anaplan";
import IBM from "./project/services/EPM/ibm/ibm";
import Onestream from "./project/services/EPM/onestream/onestream";
import AdaptInsights from "./project/services/EPM/adaptiveinsights/insights";
import Tangetik from "./project/services/EPM/tangetik/tangetik";
import Board from "./project/services/EPM/board/board";
// Services Data & Analytics
import Analytics from "./project/services/DataAnalytics/analytics";
import Predectiveanalytics from "./project/services/DataAnalytics/solutions/predectiveanalytics";
import DataAnalytics_BI from "./project/services/DataAnalytics/solutions/dataanalytics&bi";
import MachineLearning_AI from "./project/services/DataAnalytics/solutions/ML&AI";
import Prescriptiveanalytics from "./project/services/DataAnalytics/solutions/prescriptiveanalytics";
import Consulting from "./project/services/DataAnalytics/solutions/consulting";
import BigData from "./project/services/DataAnalytics/solutions/bigdata";

// Services Digital Transformation
import DigitalTransformation from "./project/services/digitaltransformation/digitaltransform";
// Staffing Augmentation 
import StaffAugment from "./project/services/staffAugmentaiom/staffAugment";
// GenAI
import GenAI from "./project/genAI/genai";
import WhatisgenAi from "./project/genAI/whatisgenai";
// Page Not Found
import PageNotFound from "./project/Errorpage";

// Carees
// import JobList from './project/careers/careers';



const routes = [
  { path: "/", title: "Provez", component: Landing },
  { path: "/about", title: "Provez | About Us", component: AboutUs },
  { path: "/contact", title: "Provez | Contact Us", component: Contact },
  // Industries
  { path: "/industries-banking", title: "Provez | Finance & Banking", component: Banking },
  { path: "/healthcare", title: "Provez | HealthCare", component: HealthCare },
  { path: "/retail", title: "Provez | Retail & E-Commerce", component: Retail },
  { path: "/manufacturing", title: "Provez | Manufacturing", component: Manufacturing },
  { path: "/energy", title: "Provez | Energy", component: Energy },
  { path: "/telecommunications", title: "Provez | Telecommunications", component: Telecommunications },
  { path: "/sector", title: "Provez | Government & Public Sector", component: Sector },
  // Services EPM
  { path: "/epm-services", title: "Provez | EPM Services", component: EPM },
  { path: "/epm-oracle", title: "Provez | Oracle EPM", component: Oracle },
  { path: "/epm-sap", title: "Provez | SAP EPM", component: SAP },
  { path: "/epm-anaplan", title: "Provez | Anaplan EPM", component: Anaplan },
  { path: "/epm-ibm", title: "Provez | IBM EPM", component: IBM },
  { path: "/epm-onestream", title: "Provez | Onestream EPM", component: Onestream },
  { path: "/epm-insights", title: "Provez | Adaptive Insights", component: AdaptInsights },
  { path: "/epm-tangetik", title: "Provez | Tangetik EPM", component: Tangetik },
  { path: "/epm-board", title: "Provez | Board EPM", component: Board },
  // Services Data & Analytics
  { path: "/data-analytics", title: "Provez | Data & Analytics", component: Analytics },
  { path: "/predictive-analytics-service", title: "Provez | Predictive Analytics", component: Predectiveanalytics },
  { path: "/data-analytics-bi-service", title: "Provez | Data Analytics & BI", component: DataAnalytics_BI },
  { path: "/machine-learning-ai-service", title: "Provez | Machine Learning & AI", component: MachineLearning_AI },
  { path: "/prescriptive-analytics-service", title: "Provez | Prescriptive Analytics", component: Prescriptiveanalytics },
  { path: "/consulting-service", title: "Provez | Consulting Services", component: Consulting },
  { path: "/bigdata-service", title: "Provez | Big Data Services", component: BigData },
  // Services Digital Transformation
  { path: "/digital-transformation-service", title: "Provez | Digital Transformation", component: DigitalTransformation },
  // Staffing Augmentation 
  { path: "/staffing-augmentation-service", title: "Provez | Staffing Augmentation Services", component: StaffAugment },
  // GenAI
  { path: "/gen-ai", title: "Provez | Gen AI", component: GenAI },
  { path: "/gen-ai-endtoendservice", title: "Provez | What is Gen AI?", component: WhatisgenAi },
  // Careers
  // { path: "/careers", title: "Provez | Careers", component: JobList },
  // Page Not Found
  { path: "*", title: "Provez", component: PageNotFound },
];

export default routes;
