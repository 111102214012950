import '../../../../css/solutions.css';
import Nav from '../../../Nav';
import Footer from '../../../footer';
import AnalyticsSolutions from '../data&analyticslist';
import Platforms from '../../../marquee_platforms/platforms';
import Join from '../../../about/joinus';


import imgPath from '../../../../images/provesyellow.png'

function Predectiveanalytics() {

    const height = {height:'16rem'}

    return (


        <div className='main-page'>

            <Nav  bgColor='rgb(255, 215, 0);' fontcolor='yellow' imgPath={imgPath} />
            <div className='about-main'>
                <div className='predictive-bg'>
                    <br /> <br /> <br /><br /> <br /> <br /> <br /><br /><br />

                    <div className='container'>
                        <div className='row'>

                            <div className='col'>
                                <div className='solutions-box'>
                                    <h4>SOLUTIONS / PREDICTIVE ANALYTICS</h4>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='container'>
                    <Platforms />

                    <div className='row'>

                        <div className='col-md-6'>
                            <h2 className='about-heading'>Predictive analytics: illuminating the future with data-driven foresight and strategic clarity.</h2>
                            <br />
                        </div>

                        <div className='col'>
                            <p className='about-company-paragraph'>
                            Enhance decision-making through advanced forecasting and predictive modeling services, leveraging cutting-edge analytics. These tools harness historical data patterns to forecast future outcomes, providing 
                            invaluable insights that empower proactive strategies and optimize business performance. By integrating sophisticated analytical techniques, organizations can navigate uncertainties with confidence, making informed decisions that drive sustainable growth and competitive advantage.
                               
                            </p>

                        </div>

                    </div>

                </div>


                <div className='services-bg'>
                    <div className='container'>
                        <div className='row'>

                            <center><h3 className='about-heading'>  <br /><br /><br />Services</h3>  <br /><br /></center>

                            <div className='col-lg-4 col-md-6'>
                                <div style={height} className='cards-industries'>
                                    <h4 className='about-heading'>Forecasting</h4>
                                    <p className='about-company-paragraph'>Predict future trends and outcomes with our forecasting services. We use advanced statistical models and machine learning algorithms to analyze historical data and provide accurate forecasts that support strategic planning and decision-making.</p>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6'>
                                <div style={height} className='cards-industries'>
                                    <h4 className='about-heading'>Predictive Modeling</h4>
                                    <p className='about-company-paragraph'>Identify potential risks and opportunities with our predictive modeling services. We build and deploy models that predict customer behavior, market trends, and operational performance, helping you stay ahead of the competition.</p>
                                </div>
                            </div>


                        </div>
                    </div>
                    <br/>   <br/>  
                </div>
                <br /> <br /> <br /> <br />
                <Join/>
                <br /> <br /> <br /> <br /> <br /> 
                                    
                <AnalyticsSolutions />

            </div>
            <Footer />
        </div>

    )

}

export default Predectiveanalytics


