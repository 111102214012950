
//  Images
import consultant from '../../images/genai/end-end-services/consultation.jpg'
import collection from '../../images/genai/end-end-services/data-collection.jpg'
import deploy_monitor from '../../images/genai/end-end-services/deploy-monitoring.png'
import innovation from '../../images/genai/end-end-services/innovation.webp'
import model from '../../images/genai/end-end-services/model-development.webp'
import quality from '../../images/genai/end-end-services/post-image_file-QUALITY_ASSURANCE.png'
import deploy_software from '../../images/genai/end-end-services/software-deployement.avif'
import tailored from '../../images/genai/end-end-services/tailored-solutions.jpg'
import training from '../../images/genai/end-end-services/training-support.webp'




function EndtoEndServices() {

    const headcolor = { color: '#08087c' }
    const borderRadius = { borderRadius: '20% 0%' }

    return (

        <div className="container-fluid endtoend-ai-bg">
            <br /> <br />

            <div className="same-line-img"><h4 style={headcolor} className='about-heading'>End-to-End Generative AI Services &nbsp;✨ </h4> </div>
            <br /><br />
            {/* First Row */}
            <div className="row">
                <div className="col-1"></div>
                <div className="col-md-4">
                    <h5 style={headcolor} className="about-heading">1.<b> Consultation and Strategy Development</b></h5>

                    <ul className="custom-list about-company-paragraph">
                        <li>
                            <span className='heading-bold'>Needs Assessment : </span>
                            Conducting a thorough analysis of the client’s requirements, existing systems, and potential areas for AI integration.
                        </li>
                        <li> <span className='heading-bold'>  Strategic Roadmap : </span>
                            Developing a strategic plan that outlines the implementation steps, timelines, and expected outcomes.
                        </li>

                    </ul>

                </div>

                <div className="col-1"></div>

                <div className="col-md-4">
                    <div className="end-end-wrapper">
                        <img style={borderRadius} className="oracle-images" src={consultant} />

                    </div></div>
            </div>


            <br /><br />
            <br /><br />
            {/* second Row */}
            <div className="row">
                <div className="col-1"></div>

                <div className="col-md-4">
                    <div className="end-end-wrapper">

                        <img style={borderRadius} className="oracle-images" src={collection} />
                    </div>
                </div>
                <div className="col-1"></div>

                <div className="col-md-4">
                    <h5 style={headcolor} className="about-heading">2.<b> Data Collection and Preparation</b></h5>

                    <ul className="custom-list about-company-paragraph">
                        <li>
                            <span className='heading-bold'>Data Gathering : </span>
                            Collecting relevant data from various sources, including structured and unstructured data.
                        </li>
                        <li> <span className='heading-bold'>  Data Cleaning and Preprocessing : </span>
                            Ensuring data quality through cleaning, normalization, and preprocessing techniques.
                        </li>
                        <li> <span className='heading-bold'>  Data Annotation : </span>
                            Labeling data to enhance the training of AI models, especially in supervised learning scenarios.
                        </li>

                    </ul>

                </div>


            </div>
            <br />  <br />  <br />


            <br /><br />
            {/* 3rd Row */}
            <div className="row">
                <div className="col-1"></div>
                <div className="col-md-4">
                    <h5 style={headcolor} className="about-heading">3. <b>Model Development and Training</b></h5>

                    <ul className="custom-list about-company-paragraph">
                        <li>
                            <span className='heading-bold'>Algorithm Selection : </span>
                            Choosing the appropriate generative AI models and algorithms based on the use case (e.g., GPT-4 for text generation, GANs for image creation).
                        </li>
                        <li> <span className='heading-bold'>  Model Training : </span>
                            Training AI models using high-quality datasets, employing techniques like supervised, unsupervised, and reinforcement learning.
                        </li>

                        <li> <span className='heading-bold'> Hyperparameter Tuning : </span>
                            Optimizing model performance through the adjustment of hyperparameters.
                        </li>

                    </ul>

                </div>

                <div className="col-1"></div>

                <div className="col-md-4">
                    <div className="end-end-wrapper">
                        <img style={borderRadius} className="oracle-images" src={model} />

                    </div>  </div>
            </div>


            <br /><br />
            <br /><br />
            {/* 4th Row */}
            <div className="row">
                <div className="col-1"></div>

                <div className="col-md-4">
                    <div className="end-end-wrapper">

                        <img style={borderRadius} className="oracle-images" src={deploy_software} />

                    </div>  </div>
                <div className="col-1"></div>

                <div className="col-md-4">
                    <h5 style={headcolor} className="about-heading">4. <b>Integration and Deployment</b></h5>

                    <ul className="custom-list about-company-paragraph">
                        <li>
                            <span className='heading-bold'>API Development : </span>
                            Creating APIs for seamless integration of AI models with existing systems and applications.
                        </li>
                        <li> <span className='heading-bold'>Cloud Deployment : </span>
                            Deploying AI models on cloud platforms for scalability and accessibility.
                        </li>
                        <li> <span className='heading-bold'>  Edge Deployment : </span>
                            Implementing AI models on edge devices for real-time processing and low-latency applications.
                        </li>

                    </ul>

                </div>
            </div>


            <br /> <br /><br />
            {/* 5th Row */}
            <div className="row">
                <div className="col-1"></div>
                <div className="col-md-4">
                    <h5 style={headcolor} className="about-heading">5. <b>Customization and Personalization</b></h5>

                    <ul className="custom-list about-company-paragraph">
                        <li>
                            <span className='heading-bold'>Tailored Solutions : </span>
                            Customizing AI models to meet specific client needs, including domain-specific adjustments and fine-tuning.
                        </li>
                        <li> <span className='heading-bold'>  Personalization Engines : </span>
                            Developing AI-driven personalization engines to enhance user experience through tailored content and recommendations.
                        </li>
                    </ul>

                </div>

                <div className="col-1"></div>

                <div className="col-md-4">
                    <div className="end-end-wrapper">
                        <img style={borderRadius} className="oracle-images" src={tailored}/>

                    </div>  </div>
            </div>


            <br /><br />
            <br /><br />
            {/* 6th Row */}
            <div className="row">
                <div className="col-1"></div>

                <div className="col-md-4">
                    <div className="end-end-wrapper">

                        <img style={borderRadius} className="oracle-images" src={quality}/>

                    </div>  </div>
                <div className="col-1"></div>

                <div className="col-md-4">
                    <h5 style={headcolor} className="about-heading">6. <b>Quality Assurance and Testing</b></h5>

                    <ul className="custom-list about-company-paragraph">
                        <li>
                            <span className='heading-bold'>Model Validation : </span>
                            Conducting rigorous testing to ensure the accuracy, reliability, and robustness of AI models.
                        </li>
                        <li> <span className='heading-bold'>User Acceptance Testing (UAT) : </span>
                            Engaging end-users in the testing process to validate the functionality and usability of AI solutions.
                        </li>


                    </ul>

                </div>
            </div>


            <br /> <br /><br />
            {/* 7th Row */}
            <div className="row">
                <div className="col-1"></div>
                <div className="col-md-4">
                    <h5 style={headcolor} className="about-heading">7. <b>Deployment and Monitoring</b></h5>

                    <ul className="custom-list about-company-paragraph">
                        <li>
                            <span className='heading-bold'>Go-Live Support : </span>
                            Assisting with the launch of AI solutions, ensuring a smooth transition and minimal disruption.
                        </li>
                        <li> <span className='heading-bold'>  Continuous Monitoring : </span>
                            Implementing monitoring tools to track model performance and detect issues in real-time.
                        </li>
                        <li> <span className='heading-bold'>  Model Maintenance : </span>
                            Providing ongoing maintenance to update models, retrain with new data, and address any arising challenges.
                        </li>
                    </ul>

                </div>

                <div className="col-1"></div>

                <div className="col-md-4">
                    <div className="end-end-wrapper">
                        <img style={borderRadius} className="oracle-images" src={deploy_monitor} />

                    </div> </div>
            </div>



            <br /><br />
            <br /><br />
            {/* 8th Row */}
            <div className="row">
                <div className="col-1"></div>

                <div className="col-md-4">
                    <div className="end-end-wrapper">
                        <img style={borderRadius} className="oracle-images" src={training}/>

                    </div> </div>
                <div className="col-1"></div>

                <div className="col-md-4">
                    <h5 style={headcolor} className="about-heading">8. <b>Training and Support</b></h5>

                    <ul className="custom-list about-company-paragraph">
                        <li>
                            <span className='heading-bold'>User Training : </span>
                            Offering training sessions and resources to educate users on how to effectively utilize AI tools and solutions.
                        </li>
                        <li> <span className='heading-bold'>Technical Support : </span>
                            Providing technical assistance and troubleshooting to ensure the smooth operation of AI systems.
                        </li>


                    </ul>

                </div>
            </div>


            <br /> <br /><br />
            {/* 9th Row */}
            <div className="row">
                <div className="col-1"></div>
                <div className="col-md-4">
                    <h5 style={headcolor} className="about-heading">9. <b> Innovation and Improvement</b></h5>

                    <ul className="custom-list about-company-paragraph">
                        <li>
                            <span className='heading-bold'>R&D : </span>
                            Investing in research and development to stay at the forefront of AI advancements and continually improve service offerings.
                        </li>
                        <li> <span className='heading-bold'>  Continuous Monitoring : </span>
                            Implementing monitoring tools to track model performance and detect issues in real-time.
                        </li>
                        <li> <span className='heading-bold'>  Feedback Loop : </span>
                            Establishing a feedback mechanism to gather user insights and enhance AI models and solutions.
                        </li>
                    </ul>

                </div>

                <div className="col-1"></div>

                <div className="col-md-4">
                    <div className="end-end-wrapper">
                        <img style={borderRadius} className="oracle-images" src={innovation} />

                    </div>  </div>
            </div>

        </div>
    )
}


export default EndtoEndServices
