




function MarqueeTools()  {
   const bgcolor = {backgroundColor : 'white'}

    return(


        <div style={bgcolor}>
            <marquee>
           <img className="keyplayers" src='https://startupstack.com/media/634786061034c50c311bd5ad/646e767caced0587cf424991_image-9%20(1).webp' alt="✨" />&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
            <img className="keyplayers" src='https://image.pitchbook.com/baZCofYFxD3nM1jGgOeWXfvpvCH1649754943425_200x200' alt="✨" />&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
            <img className="keyplayers" src='https://www.rankmarket.org/wp-content/uploads/2023/12/Speechify.png' alt="✨" /> &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
            <img className="keyplayers" src='https://cdn.softwarereviews.com/production/logos/offerings/11673/large/Replit_logo.png?1709075963'  alt="✨" /> &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
            <img className="keyplayers" src='https://startupstack.com/media/634786061034c50c311bd5ad/646e82ce9362fe964b3d6a70_copy.ai_.logo_%20(1).png' alt="✨" /> &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;

            <img className="keyplayers" src='https://logowik.com/content/uploads/images/durable-ai-website-builder3691.logowik.com.webp' alt="✨" /> &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
            <img className="keyplayers" src='https://mma.prnewswire.com/media/901269/krisp_logo_dark_Logo.jpg?p=facebook' alt="✨" /> &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
            <img className="keyplayers" src='https://assets.bloop.ai/bloop_github_logo_light.png' alt="✨" /> &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
            <img className="keyplayers" src='https://www.toolpilot.ai/cdn/shop/files/c20dd0da3eac83ee1ba5c2459cf9c6ce.jpg?v=1698061284' alt="✨" /> &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
            <img className="keyplayers" src='https://mms.businesswire.com/media/20220901005770/en/1559832/22/logo-new-font-TM.jpg' alt="✨" /> &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
            </marquee>
        

        </div>
    )
}


export default MarqueeTools

