
import talent from '../../../images/staffaugmentation/talentacqusition.jpg'
import staffing from '../../../images/staffaugmentation/Staffing(1).png'
import rapid from '../../../images/staffaugmentation/rapid.jpg'
import expert from '../../../images/staffaugmentation/experts.jpg'


function Whatweoffer() {



    return (

        <div className="conatiner">
            <center><h3 className='about-heading'>What We Offer</h3></center>
            <br /><br /><br />

            {/* First Row */}
            <div className='row'>

                <div className='col-1'></div>

                <div data-aos="fade-in" data-aos-easing="ease-in-out-quad" data-aos-duration="2800" className='col-0_5'><h3 className='epm-number'>01</h3></div>

                <div data-aos="fade-right" data-aos-easing="ease-in-out-quad" data-aos-duration="1800" className='col-md-4'>

                    <h4 className='about-heading'> Specialized Talent Acquisition</h4>


                

                    <ul className='about-company-paragraph point-change'>
                        <li>
                            <span className='heading-bold'>Data Scientists and Analysts:</span>  Our pool of highly skilled data professionals can help you with everything from data mining and statistical analysis to predictive modeling and advanced analytics.
                        </li>

                        <li><span className='heading-bold'>AI and Machine Learning Experts:</span>  Access top-tier AI and ML experts who can develop and deploy sophisticated algorithms, enhancing your data capabilities.</li>

                        <li><span className='heading-bold'>Software Developers:</span> Our developers are proficient in a variety of programming languages and frameworks, ready to contribute to your software development projects.</li>

                        <li><span className='heading-bold'>Project Managers and Business Analysts: </span>Experienced professionals who can drive your projects to success with meticulous planning, execution, and analysis.</li>
                    </ul>

                </div>

                <div className='col-1'></div>

                <div className='col-md-4'>

                    <div className="image-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="1800">
                        <img className="oracle-images" src={talent} />
                    </div>
                </div>


            </div>


            <br /><br /><br /><br />


            {/* Second Row */}
            <div className='row'>

                <div className='col-1'></div>

                <div data-aos="fade-in" data-aos-easing="ease-in-out-quad" data-aos-duration="2800" className='col-0_5'><h3 className='epm-number'>02</h3></div>

                <div data-aos="fade-right" data-aos-easing="ease-in-out-quad" data-aos-duration="1800" className='col-md-4'>

                    <h4 className='about-heading'> Flexible Engagement Models</h4>



                    <ul className='about-company-paragraph point-change'>
                        <li>
                            <span className='heading-bold'>Short-term Contracts:</span> Ideal for specific projects or peak periods where temporary expertise is required.
                        </li>

                        <li><span className='heading-bold'>Long-term Contracts:</span> Suitable for ongoing projects needing consistent, dedicated support over an extended period.</li>

                        <li><span className='heading-bold'>Project-based Staffing:</span> Tailored staffing solutions for specific project needs, ensuring you have the right team for each unique challenge.</li>
                    </ul>

                </div>

                <div className='col-1'></div>

                <div className='col-md-4'>

                    <div className="image-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="1800">
                        <img className="oracle-images" src={staffing}/>
                    </div>
                </div>

            </div>


            <br /><br /><br /><br />


            {/* Third Row */}
            <div className='row'>

                <div className='col-1'></div>

                <div data-aos="fade-in" data-aos-easing="ease-in-out-quad" data-aos-duration="2800" className='col-0_5'><h3 className='epm-number'>03</h3></div>

                <div data-aos="fade-right" data-aos-easing="ease-in-out-quad" data-aos-duration="1800" className='col-md-4'>

                    <h4 className='about-heading'>Rapid Deployment</h4>



                    <ul className='about-company-paragraph point-change'>
                        <li>
                            <span className='heading-bold'>Quick Onboarding:</span> We streamline the onboarding process to get your team up and running with minimal delay.
                        </li>

                        <li><span className='heading-bold'>Scalable Solutions:</span>  Easily scale your team up or down based on project requirements and business needs.</li>
                    </ul>

                </div>

                <div className='col-1'></div>

                <div className='col-md-4'>

                    <div className="image-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="1800">
                        <img className="oracle-images" src={rapid} />
                    </div>
                </div>

            </div>




            <br /><br /><br /><br />


            {/* Forth Row */}
            <div className='row'>

                <div className='col-1'></div>

                <div data-aos="fade-in" data-aos-easing="ease-in-out-quad" data-aos-duration="2800" className='col-0_5'><h3 className='epm-number'>04</h3></div>

                <div data-aos="fade-right" data-aos-easing="ease-in-out-quad" data-aos-duration="1800" className='col-md-4'>

                    <h4 className='about-heading'>Industry Expertise</h4>



                    <ul className='about-company-paragraph point-change'>
                        <li>
                            <span className='heading-bold'>Finance:</span> Specialized talent for financial analytics, risk management, and compliance.

                        </li>

                        <li><span className='heading-bold'>Healthcare: </span>  Experts in health informatics, patient data analytics, and regulatory adherence.</li>

                        <li><span className='heading-bold'>Retail: </span>  Professionals adept at consumer behavior analysis, supply chain optimization, and sales forecasting.</li>

                        <li><span className='heading-bold'>Manufacturing: </span>  Skilled personnel for process optimization, predictive maintenance, and quality control.</li>
                    </ul>

                </div>

                <div className='col-1'></div>

                <div className='col-md-4'>

                    <div className="image-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="1800">
                        <img className="oracle-images" src={expert} />
                    </div>
                </div>

            </div>


            <br /><br /><br /><br />




        </div>
    )
}


export default Whatweoffer