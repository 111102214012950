
import '../../../css/services/staffaugment.css';



function WhychooseStaff()    {

    const padding = {padding:'10px'}

    return(
        <div  className="container mt-5 whyoracle">

        <br/><br/>
        <center><h3 className='about-heading'>Why Choose Provez for Staffing Augmentation?</h3></center>
        <br/><br/>
        <div style={padding}  className="row">
            {/* Card 1 */}
            <div className="col-md-6 col-lg-6 mb-4">
                <div className="card h-100">
                    <div  className="card-body">
                        <h5 className="card-title">Vetted Professionals</h5>
                        <p className="card-text">
                        All our candidates undergo a rigorous screening process to ensure they meet the highest standards of expertise and reliability.
                        </p>
                    </div>
                </div>
            </div>
            {/* Card 2 */}
            <div className="col-md-6 col-lg-6 mb-4">
                <div className="card h-100">
                    <div className="card-body">
                        <h5 className="card-title">Customized Solutions</h5>
                        <p className="card-text">
                        We work closely with you to understand your specific needs and tailor our services accordingly.
                        </p>
                    </div>
                </div>
            </div>
            {/* Card 3 */}
            <div className="col-md-6 col-lg-6 mb-4">
                <div className="card h-100">
                    <div className="card-body">
                        <h5 className="card-title">Cost Efficiency</h5>
                        <p className="card-text">
                        Reduce hiring overheads and avoid long-term commitments with flexible staffing solutions that align with your budget.

                        </p>
                    </div>
                </div>
            </div>
             {/* Card 4 */}
             <div  className="col-md-6 col-lg-6 mb-4">
                <div className="card h-100">
                    <div className="card-body">
                        <h5 className="card-title">Seamless Integration</h5>
                        <p className="card-text">
                        Our professionals integrate seamlessly into your existing teams and workflows, ensuring continuity and productivity.

                        </p>
                    </div>
                </div>
            </div>
          
           
        </div>
        <br/><br/>
    </div>
    )
}


export default WhychooseStaff