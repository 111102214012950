import Nav from "../../Nav"
import Footer from "../../footer"
import '../../../css/services/data&analytics.css';
import Platforms from '../../marquee_platforms/platforms';
import Join from "../../about/joinus";
import AnalyticsSolutions from "./data&analyticslist";

import imgPath from '../../../images/provesyellow.png';



function Analytics()  {
  
    return (
        <div className='main-page'>
            <Nav bgColor='#000000a8' imgPath={imgPath}/>
            <div className='about-main'>
                <div className="analytics-image">
                <br /> <br /> <br /><br /> <br /> <br /> <br /><br /><br />

                    <div className='container'>
                        <div className='row'>
                            <div className='col'>
                              
                                <h4  className='contact-heading'>DATA & ANALYTICS</h4>
                            </div>
                            <div className='col'></div>
                        </div>
                    </div>

                </div>
                <div className='container'>
                    <Platforms />

                    <div className='row'>

                        <div className='col-md-6'>
                            <h2 className='about-heading'>Harness the Power of Data with Provez</h2>
                            <br />
                        </div>

                        <div className='col'>
                            <p className='industries-readmore'>
                            At Provez, we provide a comprehensive range of analytics services designed to help organizations unlock the full potential of their data. Our expert team leverages 
                            advanced analytics, machine learning, and artificial intelligence to transform raw data into actionable insights, enabling you to make informed decisions and drive business growth.
                                <br />  <br />

                            </p>
                            <br /><br /><br /><br />

                        </div>

        

                    </div>  

                    <br /><br /><br /><br />
                    <AnalyticsSolutions/>
                 

                </div>



                <br /><br />  <br /><br />

                <Join />
                <br /><br />  <br /><br />




            </div>


            <Footer />

        </div>
    )
}



export default Analytics