
import { Link } from "react-router-dom"


function ExploreGenAI() {

    return (
        <div className="container-fluid explore-genai-bg">
            <br />  <br />
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-6">
                    <div>
                        <h3 className="contact-heading">Discover What Our AI&nbsp;✨ Solutions Can Create.</h3><br />
                        <Link to='/gen-ai' ><buton data-aos="fade-left" data-aos-easing="ease-in-out-quad" data-aos-duration="500" className='explore-button'><span style={{ fontFamily: 'Montserrat' }}>TAP</span>  &nbsp;<i class="bi bi-arrow-right"></i></buton></Link>  </div>
                </div>
            </div>

            <br /><br />

        </div>
    )
}

export default ExploreGenAI