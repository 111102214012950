
import Nav from '../Nav';
import Footer from '../footer';
import imgPath from '../../images/provesyellow.png'
import MarqueeKeyPlayers from './keyplayersmarquee';
import EndtoEndServices from './endtoendservice';
import Join from '../about/joinus';
import KeyUses from './keyuses';
import BenefitsAI from './benefits';

//  Images
import ai_robot from '../../images/genai/whatisai-robot.png'
import power from '../../images/genai/ai-power.webp'




function WhatisgenAi() {

    return (
        <div className='main-page'>
            <Nav bgColor="#08087c" fontcolor='yellow' imgPath={imgPath} />
            <div className='about-main'>

                <br /> <br /> <br /><br />
                <div className='container-fluid whatisgenai-bg'>

                    <div className='row'>

                        <div className='col-2'>
                        </div>

                        <div className='col-md-4 about-company-paragraph'>

                            <br /> <br /><br /> <br />
                            <h4 className='about-heading'> What is Gen AI ?</h4>

                            <p>Generative AI refers to a category of artificial intelligence focused on creating content, such as text, images, audio, or video,
                                that is original and new rather than simply regurgitating or manipulating existing data. Unlike traditional AI models that rely on rules and predefined algorithms,
                                generative AI employs deep learning techniques, particularly through neural networks, to understand and replicate patterns in data. This technology enables machines
                                to generate realistic outputs that mimic human creativity and decision-making processes.</p> <br /> <br />
                        </div>

                        <div className='col'>
                            <img className='whatisgen-image' src={ai_robot} />

                        </div>

                        <div className='col'></div>
                    </div>
                    <br />
                    <MarqueeKeyPlayers /><br /><br />
                    <div className='row'>

                        <div className='col-2'>
                        </div>

                        <div className='col'>
                            <img className='whatisgen-image' src={power} />

                        </div>

                        <div className='col-md-4 about-company-paragraph'>
                            <br /> <br />
                            <h4> The Power of Generative AI</h4>

                            <p>The power of generative AI lies in its ability to innovate and accelerate processes that traditionally required significant human input.
                                It can automate complex tasks like content creation, design prototyping, and even scientific research, thereby saving time and resources while
                                fostering creativity. Moreover, its capacity for personalization allows for tailored user experiences in areas such as marketing, customer service, and education.</p> <br /> <br />
                        </div>


                        <div className='col'></div>
                    </div>
                    <br/>

                </div>
    
                <EndtoEndServices />
              
                <KeyUses/>
                <br /><br /><br />
                <BenefitsAI/>

                <Join />
                <br /><br /><br />
            </div><Footer />
        </div>
    )
}


export default WhatisgenAi
