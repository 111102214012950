import '../../../../css/services/epm.css';
import Nav from '../../../Nav';
import Footer from '../../../footer';
import EPMLIST from '../epmserviceslist';
import Join from '../../../about/joinus';


import tangetik from '../../../../images/epmlist/epmservices/tangetik/tangetik.png'
import imgPath from '../../../../images/proveslogo(1).png'


function Tangetik()  {


    return(
        <div className='main-page'>
        <Nav bgColor='rgb(255, 215, 0);' fontcolor='black' imgPath={imgPath}/>


        <div className='about-main'>
            <div className="tangetik-image">
                <br /> <br /> <br /><br /> <br /> <br /> <br /><br />

                <div className='container'>
                    <div className='row'>

                        <div className='col'>
                            <div className='solutions-box'>
                                <h4 className='contact-heading'>EPM / TANGETIK</h4>

                            </div>
                        </div>
                    </div>
                </div>

            </div>



            <br /> <br /> <br /> <br /> <br />


            <div className='container'>
                {/* First Row */}
                <div className='row'>


                    <div className='col-1'></div>

                    <div data-aos="fade-in" data-aos-easing="ease-in-out-quad" data-aos-duration="2800" className='col-0_5'><h3 className='epm-number'>01</h3></div>

                    <div data-aos="fade-right" data-aos-easing="ease-in-out-quad" data-aos-duration="1800" className='col-md-4'>

                        <h4 className='about-heading'>Financial Performance Management:</h4>
                        <p className='about-company-paragraph'>CCH Tagetik provides advanced financial performance management solutions for budgeting, planning, consolidation, and reporting.
                             We offer end-to-end services to help you implement and optimize Tagetik, ensuring improved financial accuracy and efficiency.</p>

                    </div>

                    <div className='col-1'></div>


                    <div className='col-md-4'>

                        <div className="image-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="1800">
                            <img className="oracle-images" src='https://www.gartner.com/pi/vendorimages/wolters-kluwer_cloud-extended-planning-and-analysis-solutions_1636666883153.png' />
                        </div>
                    </div>

                </div>

                <br /><br />  <br /><br />

            </div>


            <br /><br />
            <EPMLIST />
            <br /><br />
            <Join />
            <br /><br /><br /><br />
        </div>
        <Footer />
    </div>

    )
}


export default Tangetik