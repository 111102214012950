import { Link } from 'react-router-dom';
import '../../css/about.css';




function Join() {
    
    return (
        <div className='container back-color'>
        <div className='row'>
            <div className='col-12'>
                <div className='content-box'>
                    <center>
                        <br /><br /> <br /><br />
                        <h2 className='about-company-header'>Ready To Find Out More?</h2>
                        <br />
                    </center>
                    <div className='row'>
                        <div className='col-2'></div>
                        <div className='col-md-6'>
                            <h6 className='about-company-header'>
                                Whether you are looking to optimize your operations, enhance customer experiences, or gain a competitive edge,
                                Provez is here to help you achieve your goals. Join us on our journey to transform data into a strategic asset that fuels growth and innovation.
                            </h6>
                        </div>
                        <div className='col'>
                            <br/>
                           <Link to='/contact'> <button className='read-more'>Join Us Today</button></Link>
                        </div>
                        
                    </div>
                </div>
                <br /><br /> <br /><br />
            </div>
        </div>
    </div>
    
    )
}


export default Join
