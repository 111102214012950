
import '../../../css/services/digitaltransform.css';






function WhyDgTransform()   {


    const radius = { borderRadius: '0px' }
    const padding = {padding:'10px'}

    return (
        <div className="container mt-5 whyoracle">

        <br/><br/>
        <center><h3 className='about-heading'>Why Choose Provez for Digital Transformation?</h3></center>
        <br/><br/>
        <div style={padding} className="row">
            {/* Card 1 */}
            <div className="col-md-6 col-lg-6 mb-4">
                <div className="card h-100">
                    <div className="card-body">
                        <h5 className="card-title">Expertise and Experience</h5>
                        <p className="card-text">
                        Our team comprises seasoned professionals with extensive experience in digital transformation across various industries.
                        </p>
                    </div>
                </div>
            </div>
            {/* Card 2 */}
            <div className="col-md-6 col-lg-6 mb-4">
                <div className="card h-100">
                    <div className="card-body">
                        <h5 className="card-title">Comprehensive Solutions:</h5>
                        <p className="card-text">
                        We offer end-to-end digital transformation services, from strategy development to implementation and continuous improvement.
                        </p>
                    </div>
                </div>
            </div>
            {/* Card 3 */}
            <div className="col-md-6 col-lg-6 mb-4">
                <div className="card h-100">
                    <div className="card-body">
                        <h5 className="card-title">Customer-Centric Approach</h5>
                        <p className="card-text">
                        We place your business objectives and customer needs at the heart of our solutions, ensuring maximum impact and value.

                        </p>
                    </div>
                </div>
            </div>
             {/* Card 4 */}
             <div className="col-md-6 col-lg-6 mb-4">
                <div className="card h-100">
                    <div className="card-body">
                        <h5 className="card-title">Innovative Technologies</h5>
                        <p className="card-text">
                        We stay at the forefront of technological advancements, leveraging the latest tools and frameworks to drive your digital journey.

                        </p>
                    </div>
                </div>
            </div>
          
           
        </div>
        <br/><br/>
    </div>
    )
}


export default WhyDgTransform