import '../../../../css/services/epm.css';
import Nav from '../../../Nav';
import Footer from '../../../footer';
import EPMLIST from '../epmserviceslist';
import Join from '../../../about/joinus';


//  images
import bi from '../../../../images/epmlist/epmservices/board/perform&bi.png'
import imgPath from '../../../../images/provesyellow.png'



function Board()  {
    return(

        <div className='main-page'>
            <Nav bgColor='rgb(255, 215, 0);' fontcolor='yellow' imgPath={imgPath}/>


            <div className='about-main'>
                <div className="board-image">
                    <br /> <br /> <br /><br /> <br /> <br /> <br /><br />

                    <div className='container'>
                        <div className='row'>

                            <div className='col'>
                                <div className='solutions-box'>
                                    <h4 className='contact-heading'>EPM / BOARD INTERNATIONAL</h4>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>



                <br /> <br /> <br /> <br /> <br />


                <div className='container'>
                    {/* First Row */}
                    <div className='row'>


                        <div className='col-1'></div>

                        <div data-aos="fade-in" data-aos-easing="ease-in-out-quad" data-aos-duration="2800" className='col-0_5'><h3 className='epm-number'>01</h3></div>

                        <div data-aos="fade-right" data-aos-easing="ease-in-out-quad" data-aos-duration="1800" className='col-md-4'>

                            <h4 className='about-heading'>Performance Management and BI</h4>
                            <p className='about-company-paragraph'>Board International offers an integrated platform for performance management, business intelligence, and predictive analytics. 
                                Our experts help you implement and customize Board solutions to enhance planning, analysis, and reporting capabilities.</p>

                        </div>

                        <div className='col-1'></div>


                        <div className='col-md-4'>

                            <div className="image-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="1800">
                                <img className="oracle-images" src={bi}/>
                            </div>
                        </div>

                    </div>

                    <br /><br />  <br /><br />

                </div>


                <br /><br />
                <EPMLIST />
                <br /><br />
                <Join />
                <br /><br /><br /><br />
            </div>
            <Footer />
        </div>
    )
}


export default Board