


// Images
import fb from '../../images/social-media/fb.webp';
import linkedin from '../../images/social-media/linkedin.webp';
import twitter from '../../images/social-media/twitter-x.png';


function OurAddress() {

    return (

        <div className="container-fluid ouraddress-bg">

            <div className="container">

                <div className="row">

                    <h3 className="about-heading">Our Address <i class="bi bi-geo-alt"></i> <br /> <br /></h3>


                    <div className="col-md-4 address">
                        <h4 className='location'>Bangalore</h4><br />
                        Provez Heuristics Private Limited<br />
                        WeWork Cherry Hills, 4th Floor<br />
                        Embassy Golf Links Software Park<br />
                        Bangalore, Karnataka - 560071<br />
                        India
                        <br /><br />
                    </div>

                    <div className="col-md-4 address">
                        <h4 className='location'>Dubai</h4><br />

                        Provez Analytics FZ LLC<br />
                        DIC Building, Al Sufouh<br />
                        Dubai Internet City<br />
                        Dubai-73000<br />
                        United Arab Emirates
                        <br /><br />

                    </div>


                    <div className="col-md-4 address">
                        <h4 className='location'>United States</h4><br />

                        Provez Inc<br />
                        1007 N Orange St.<br />
                        4th Floor, Suite #3717<br />
                        Wilmington, Delaware 19801<br />
                        United States


                    </div>




                    <div className='col'> <br /><br />
                        <h6 className='address'>

                            <p><i className="bi bi-send-fill"></i> &nbsp; info@provez.ai</p>
                            <p><i className="bi bi-telephone-fill"></i>&nbsp; +1(415) 830-3704</p>
                        </h6><br />

                        <div>
                            <a href='https://www.linkedin.com/company/prowessanalytics'>
                                <img title='Linkedin' className='social-images' src={linkedin} alt='LinkedIn' />
                            </a>&nbsp;&nbsp;
                            <a href='https://www.twitter.com/prowessanalytics'>
                                <img title='X' className='social-images' src={twitter} alt='Twitter' />
                            </a>&nbsp;&nbsp;
                            <a href='https://www.facebook.com/prowessanalytics'>
                                <img title='Facebook' className='social-images' src={fb} alt='Facebook' />
                            </a>
                        </div>
                        <br /><br /><br />
                    </div>

                </div>


            </div></div>
    )

}


export default OurAddress