import '../../css/industries.css';
import Nav from '../Nav';
import Footer from '../footer';
import Join from '../about/joinus';
import Platforms from '../marquee_platforms/platforms';
import Industries from '../about/industries';



import imgPath from '../../images/proveslogo(1).png'


function HealthCare() {

    const height ={height:'16rem'}


    return (


        <div className='main-page'>

            <Nav bgColor='rgb(255, 215, 0);' fontcolor='black' imgPath={imgPath}/>
            <div className='about-main'>
                <div className='healthcare-bg'>
                    <br /> <br /> <br /><br /> <br /> <br /> <br /><br />

                    <div className='container'>
                        <div className='row'>

                            <div className='col'>
                                <div className='solutions-box'>
                                    <h4 className='finance-header'>INDUSTRY / HEALTHCARE</h4>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='container'>
                    <Platforms />

                    <div className='row'>

                     

                    </div>

                </div>


                <div className='services-bg'>
                    <div className='container'>
                        <div className='row'>

                            <center><h3 className='about-heading'>  <br />What We Do</h3>  <br /><br /></center>

                            <div className='col-lg-4 col-md-6'>
                                <div style={height} className='cards-industries'>
                                    <h4 className='about-heading'>Patient Care  </h4>
                                    <p className='industry-text'>

                                    Improve patient outcomes and operational efficiency with our healthcare analytics solutions. We help healthcare providers analyze patient data to enhance care quality, reduce costs, and personalize treatment plans.
                                    </p>
                                </div>
                            </div>


                            <div className='col-lg-4 col-md-6'>
                                <div style={height}  className='cards-industries'>
                                    <h4 className='about-heading'>Clinical Research</h4>
                                    <p className='industry-text'>

                                    Accelerate drug discovery and clinical trials with our advanced analytics and machine learning solutions. We assist pharmaceutical companies in identifying potential drug candidates, optimizing trial designs, and analyzing clinical data.
                                    </p>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6'>
                                <div style={height}  className='cards-industries'>
                                    <h4 className='about-heading'>Healthcare Operations</h4>
                                    <p className='industry-text'>

                                    Enhance operational efficiency and resource allocation with our data-driven insights. We help healthcare organizations optimize scheduling, supply chain management, and financial performance.
                                    </p>
                                </div>
                                <br /><br />
                            </div>
                        

                        </div>
                    </div>
                </div>
                <br /> <br /> <br /> <br />
                <Join />
                <br /> <br /> 

                <Industries />


            </div>
            <br /> <br />  <br /> <br />
            <Footer />
        </div>


    )

}

export default HealthCare
