
import '../../../css/services/staffaugment.css';
import Nav from "../../Nav"
import Footer from "../../footer"
import Platforms from '../../marquee_platforms/platforms';
import Join from "../../about/joinus";
import imgPath from '../../../images/provesyellow.png';


import WhychooseStaff from './whychoosestaff';
import GetStartStaff from './getstartstaff';
import Whatweoffer from './whatweoffer';



function StaffAugment() {

    return (

        <div className='main-page'>
            <Nav bgColor='#000000a8' imgPath={imgPath} />
            <div className='about-main'>
                <div className="staffaugmentation-image">
                <br /><br /><br /><br /><br /><br /> <br /><br />
                    <div className='container'>
                        <div className='row'>
                            <div className='col'>
                              
                                <h4 className='contact-heading'>STAFFING AUGMENTATION SERVICES</h4>
                            </div>
                            <div className='col'></div>
                        </div>
                    </div>

                </div>
                <div className='container'>
                    <Platforms />

                    <div className='row'>

                        <div className='col-md-6'>
                            <h2 className='about-heading'>Enhance your team with expert talent for optimal flexibility and productivity through staffing augmentation.</h2>
                            <br />
                        </div>

                        <div className='col'>
                            <p className='industries-readmore'>At Provez, we understand that the right talent is crucial for driving your business forward. Our Staffing Augmentation Services are designed to provide
                                you with the expertise and flexibility needed to meet your evolving project demands, ensuring you have the right skills at the right time.
                                <br />  <br />
                            </p>

                        </div>

                    </div>

                    <br /><br /><br /><br />

                    <h4 className='whatishead'></h4>
                    <br /><br /><br /><br />


                    <Whatweoffer />
                    <br /><br /><br /><br />
                    <h4 className='whatishead'></h4>
                    <br /><br /><br /><br />

                    <WhychooseStaff />

                    <br /><br /><br /><br />

                    <h4 className='whatishead'></h4>
                    <br /><br /><br /><br />
                    <GetStartStaff />
                    <br /><br /><br /><br />

                </div>




            </div>


            <Footer />

        </div>
    )
}



export default StaffAugment
