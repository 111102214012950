import '../../../../css/solutions.css';
import Nav from '../../../Nav';
import Footer from '../../../footer';
import AnalyticsSolutions from '../data&analyticslist';
import Platforms from '../../../marquee_platforms/platforms';
import Join from '../../../about/joinus';


import imgPath from '../../../../images/provesyellow.png'


function Prescriptiveanalytics() {
    const height = { height: '17rem' }

    return (


        <div className='main-page'>

            <Nav bgColor='rgb(255, 215, 0);' fontcolor='yellow' imgPath={imgPath}/>
            <div className='about-main'>
                <div className='prescriptive-bg'>
                    <br /> <br /> <br /><br /> <br /> <br /> <br /><br /><br />

                    <div className='container'>
                        <div className='row'>

                            <div className='col'>
                                <div className='solutions-box'>
                                    <h4>SOLUTIONS / PRESCRIPTIVE ANALYTICS </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='container'>
                    <Platforms />

                    <div className='row'>

                        <div className='col-md-6'>
                            <h2 className='about-heading'>Optimized Workflow Efficiency Drives Business Excellence </h2>
                            <br />
                        </div>

                        <div className='col'>
                            <p className='about-company-paragraph'>
                                Perspective Analytics as a Service provides real-time insights and predictive analytics, enabling businesses to make informed decisions and optimize performance.
                                By leveraging advanced data analysis techniques, it empowers organizations to anticipate trends, identify opportunities, and stay competitive in dynamic markets, fostering strategic growth and operational efficiency.

                            </p>

                        </div>

                    </div>

                </div>


                <div className='services-bg'>
                    <div className='container'>
                        <div className='row'>

                            <center><h3 className='about-heading'>  <br /><br /><br />Services</h3>  <br /><br /></center>

                            <div className='col-lg-4 col-md-6'>
                                <div style={height} className='cards-industries'>
                                    <h4 className='about-heading'>Optimization</h4>
                                    <p className='about-company-paragraph'>Optimize your business processes with our prescriptive analytics services. We use sophisticated algorithms to recommend the best course of action, whether it’s optimizing supply chain operations, pricing strategies, or resource allocation.</p>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6'>
                                <div style={height} className='cards-industries'>
                                    <h4 className='about-heading'>Decision Support</h4>
                                    <p className='about-company-paragraph'>Enhance your decision-making capabilities with our decision support systems. We provide data-driven insights and recommendations that help you make informed choices, reduce uncertainty, and improve overall efficiency.</p>
                                </div>
                            </div>


                        </div>
                    </div>
                    <br />   <br />
                </div>
                <br /> <br /> <br /> <br />
                <Join />
                <br /> <br /> <br /> <br /> <br />

                <AnalyticsSolutions />

            </div>
            <Footer />
        </div>


    )


}

export default Prescriptiveanalytics