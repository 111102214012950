import '../../../../css/services/epm.css';
import { Link } from 'react-router-dom';



function TransformOracle()   {
    return(


<div className='container back-color'>
        <div className='row'>
            <div className='col-12'>
                <div className='content-box'>
                    <center>
                        <br /><br /> <br /><br />
                        <h2 className='about-company-header'>Transform Your EPM with Oracle EPM Cloud</h2>
                        <br />
                    </center>
                    <div className='row'>
                        <div className='col-2'></div>
                        <div className='col-md-6'>
                            <h6 className='about-company-header'>
                            Unlock the full potential of your enterprise performance management with Provez Analytics and Oracle EPM Cloud. Our expert services enable you
                             to enhance financial planning, streamline reporting, and drive strategic insights.
                            </h6>
                        </div>
                        <div className='col'>
                            <br/>
                           <Link to='/contact'> <button className='read-more'>Join Us Today</button></Link>
                        </div>
                        
                    </div>
                </div>
                <br /><br /> <br /><br />
            </div>
        </div>
    </div>
    )
}


export default TransformOracle
