

import { Link } from "react-router-dom"




function PageNotFound()    {

    return(

        <div className="not-found-container">
        <h1 className="not-found-title">404</h1>
        <p className="not-found-text">Oops! Page not found.</p>
        <p className="not-found-description">The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p><br/>
      <Link to='/'>  <button className="read-more">Go Back</button></Link>
    </div>
    )
}


export default PageNotFound