

import robot from '../../images/genai/symbols/robot.jpg'


function BenefitsAI() {
    const headcolor = { color: '#08087c' }
    const width = {width:'17rem'}


    return (
        <div className="container-fluid keyuses-bg">

            <div className="same-line-img"><h4 style={headcolor} className='about-heading'>Benefits of End-to-End Generative AI Services &nbsp;✨</h4>  </div>
            <br/>

            <div className="row">
                <div className="col-2"></div>

                <div className="col-md-5">
                    <ul className="key-use-image about-company-paragraph">
                        <li data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="500">
                      <u>  Efficiency</u>  : Automates repetitive and time-consuming tasks, freeing up resources for strategic activities.
                        </li>

                        <li data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="500" data-aos-delay="300" >
                       <u> Scalability</u> : Enables businesses to scale operations rapidly by leveraging cloud-based AI solutions.
                        </li>

                        <li data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="500" data-aos-delay="400" >
                       <u> Innovation</u> : Fosters innovation by providing advanced tools and models that can generate creative and novel solutions.
                        </li>

                        <li data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="500" data-aos-delay="500" >
                       <u> Customization</u> : Offers tailored solutions that meet the specific needs of various industries and applications.
                        </li>

                        <li data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="500" data-aos-delay="600" >
                      <u>  Competitive Advantage</u> : Enhances decision-making and operational efficiency, providing a competitive edge in the market.
                        </li>
                    </ul>
                </div>

                <div className="col-1"></div>

                <div className="col">
                    <br/> <br/>
                    <img style={width} className='ai-side-images' src={robot} />
                </div>
            </div>
            <br/>   <br/>

        </div>
    )
}


export default BenefitsAI