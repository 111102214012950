import '../../../../css/services/epm.css';
import Nav from '../../../Nav';
import Footer from '../../../footer';
import WhyOracle from './whyoracle';
import TransformOracle from './transformoracle';
import EPMLIST from '../epmserviceslist';

// images
import closed from '../../../../images/epmlist/epmservices/oracle/arrangement-with-box-closed-sign.jpg'
import cloud from '../../../../images/epmlist/epmservices/oracle/cloud-file-sharing-banner-background.jpg'
import finance from '../../../../images/epmlist/epmservices/oracle/hand-holding-growth-arrow-with-coins.jpg'
import data from '../../../../images/epmlist/epmservices/oracle/oracle-data.jpg'
import report from '../../../../images/epmlist/epmservices/oracle/front-view-graphics-schedules-getting-checked-by-young-lady.jpg'
import strategy from '../../../../images/epmlist/epmservices/oracle/strategy.jpg'
import imgPath from '../../../../images/proveslogo(1).png'



function Oracle() {
    return (
        <div className='main-page'>
            <Nav bgColor='rgb(255, 215, 0);' fontcolor='black' imgPath={imgPath}/>



            <div className='about-main'>
                <div className="oracle-image">
                    <br /> <br /> <br /><br /> <br /> <br /> <br /><br />

                    <div className='container'>
                        <div className='row'>

                            <div className='col'>
                                <div className='solutions-box'>
                                    <h4 className='about-heading'>EPM / ORACLE</h4>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <br /> <br /> <br /> <br /> <br />

                <div className='container'>


                    {/* First Row */}
                    <div className='row'>


                        <div className='col-1'></div>

                        <div data-aos="fade-in" data-aos-easing="ease-in-out-quad" data-aos-duration="2800" className='col-0_5'><h3 className='epm-number'>01</h3></div>

                        <div data-aos="fade-right" data-aos-easing="ease-in-out-quad" data-aos-duration="1800" className='col-md-4'>

                            <h4 className='about-heading'>Oracle EPM Cloud Implementation</h4>
                            <p className='about-company-paragraph'>Our implementation services ensure a smooth and efficient transition to Oracle EPM Cloud. We work closely with your team to understand your unique business needs and configure the EPM system
                                to meet your specific requirements. Our goal is to ensure a seamless integration with your existing IT infrastructure, minimizing disruption and maximizing value.</p>

                        </div>

                        <div className='col-1'></div>


                        <div className='col-md-4'>

                            <div className="image-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="1800">
                                <img className="oracle-images" src={cloud} />
                            </div>
                        </div>


                    </div>

                    <br /><br />  <br /><br />

                    {/* Second Row */}

                    <div className='row'>


                        <div className='col-1'></div>

                        <div data-aos="fade-in" data-aos-easing="ease-in-out-quad" data-aos-duration="2800" className='col-0_5'><h3 className='epm-number'>02</h3></div>

                        <div data-aos="fade-right" data-aos-easing="ease-in-out-quad" data-aos-duration="1800" className='col-md-4'>

                            <h4 className='about-heading'>Financial Planning and Budgeting</h4>
                            <p className='about-company-paragraph'>We help you streamline your financial planning and budgeting processes with Oracle Planning and Budgeting Cloud Service (PBCS). Our solutions enable you to create accurate and dynamic financial plans, conduct scenario analysis,
                                and make informed decisions based on real-time data. With our expertise, you can improve forecasting accuracy and enhance financial agility.</p>

                        </div>

                        <div className='col-1'></div>


                        <div className='col-md-4'>

                            <div className="image-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="1800">
                                <img className="oracle-images" src={finance} />
                            </div>
                        </div>

                    </div>

                    <br /><br />  <br /><br />


                    {/* THird Row */}

                    <div className='row'>


                        <div className='col-1'></div>

                        <div data-aos="fade-in" data-aos-easing="ease-in-out-quad" data-aos-duration="2800" className='col-0_5'><h3 className='epm-number'>03</h3></div>

                        <div data-aos="fade-right" data-aos-easing="ease-in-out-quad" data-aos-duration="1800" className='col-md-4'>

                            <h4 className='about-heading'>Financial Consolidation and Close</h4>
                            <p className='about-company-paragraph'>Our team assists you in accelerating your financial close and consolidation processes with Oracle Financial Consolidation and Close Cloud Service (FCCS). We ensure compliance with regulatory
                                requirements and improve the accuracy of your consolidated financial statements. Our solutions help you reduce close cycles and enhance transparency and control.</p>

                        </div>

                        <div className='col-1'></div>


                        <div className='col-md-4'>

                            <div className="image-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="1800">
                                <img className="oracle-images" src={closed} />
                            </div>
                        </div>

                    </div>


                    <br /><br />  <br /><br />

                    {/* Fourth Row */}

                    <div className='row'>


                        <div className='col-1'></div>

                        <div data-aos="fade-in" data-aos-easing="ease-in-out-quad" data-aos-duration="2800" className='col-0_5'><h3 className='epm-number'>04</h3></div>

                        <div data-aos="fade-right" data-aos-easing="ease-in-out-quad" data-aos-duration="1800" className='col-md-4'>

                            <h4 className='about-heading'>Reporting and Analytics</h4>
                            <p className='about-company-paragraph'>We provide robust reporting and analytics solutions using Oracle EPM Cloud. Our services include designing and developing custom reports, dashboards, and visualizations that deliver
                                actionable insights. With our expertise, you can gain a comprehensive view of your financial performance and make data-driven decisions with confidence.</p>

                        </div>

                        <div className='col-1'></div>


                        <div className='col-md-4'>

                            <div className="image-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="1800">
                                <img className="oracle-images" src={report} />
                            </div>
                        </div>

                    </div>


                    <br /><br />  <br /><br />

                    {/* Fifth Row */}

                    <div className='row'>


                        <div className='col-1'></div>

                        <div data-aos="fade-in" data-aos-easing="ease-in-out-quad" data-aos-duration="2800" className='col-0_5'><h3 className='epm-number'>05</h3></div>

                        <div data-aos="fade-right" data-aos-easing="ease-in-out-quad" data-aos-duration="1800" className='col-md-4'>

                            <h4 className='about-heading'>Data Integration and Management</h4>
                            <p className='about-company-paragraph'>We ensure seamless data integration and management across your enterprise systems. Our team configures Oracle EPM Cloud to integrate with your ERP, CRM, and other data sources,
                                ensuring that your EPM processes are driven by accurate and up-to-date information. We help you maintain data integrity and consistency across your organization.</p>

                        </div>

                        <div className='col-1'></div>


                        <div className='col-md-4'>

                            <div className="image-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="1800">
                                <img className="oracle-images" src={data} />
                            </div>
                        </div>

                    </div>



                    <br /><br />  <br /><br />

                    {/* Sixth Row */}

                    <div className='row'>


                        <div className='col-1'></div>

                        <div data-aos="fade-in" data-aos-easing="ease-in-out-quad" data-aos-duration="2800" className='col-0_5'><h3 className='epm-number'>06</h3></div>

                        <div data-aos="fade-right" data-aos-easing="ease-in-out-quad" data-aos-duration="1800" className='col-md-4'>

                            <h4 className='about-heading'>Strategic Modeling</h4>
                            <p className='about-company-paragraph'>Our strategic modeling services help you navigate complex business environments and develop long-term financial strategies.
                                 Using Oracle EPM Cloud's strategic modeling capabilities, we enable you to assess the financial impact of various scenarios, optimize capital structure, and plan for future growth and profitability.</p>

                        </div>

                        <div className='col-1'></div>


                        <div className='col-md-4'>

                            <div className="image-wrapper" data-aos="fade-up" data-aos-easing="ease-in-out-quad" data-aos-duration="1800">
                                <img className="oracle-images" src={strategy} />
                            </div>
                        </div>

                    </div>

                    <br /><br />  <br /><br />
                    <WhyOracle />

                </div>
                <EPMLIST />
                <br /><br /><br />
                <TransformOracle />

                <br /><br /><br /><br />



            </div>

            <Footer />

        </div>
    )
}



export default Oracle

